/** Error response from the server-side */
type ErrorResponse = {
    /** Exception type */
    type: string;

    /** User-facing error message */
    title: string;

    /** HTTP status code */
    status: number;

    /** Further error information */
    errors?: {
        /** Error information */
        [serverSideFieldName: string]: string[];
    };
};

/** Error response from the server-side controllers. */
export class RequestError {
    /** Returned server-side error response object. */
    public response: ErrorResponse;

    /** Top-level user-facing error message. */
    public get message(): string {
        return this.response.title || this.message || "An error occurred";
    }

    /** Detailed error message. */
    public get fullMessage(): string {
        const fieldErrors = this.response.errors
            ? Object.entries(this.response.errors).map(([key, value]) => `(${key}) ${value}`)
            : [];

        return `${this.response.status} ${this.response.title}${
            fieldErrors.length > 0 ? `:\r\n${fieldErrors.join("\r\n")}` : ""
        }`;
    }

    /**
     * Initialises a new instance.
     * @param response Returned server-side error response object.
     */
    constructor(response: ErrorResponse) {
        this.response = response;
    }
}

/** Helper class for querying data */
export default abstract class ApiHelper {
    /** Hostname against which to perform the requests, defaults to same hostname as the client. */
    private static readonly hostname = process.env.REACT_APP_SERVER_HOSTNAME || "";

    /**
     * Queries the specified endpoint.
     * @param controller Controller to query.
     * @param action Action within the controller to query.
     * @param method HTTP method to use in the query.
     * @param body HTTP body, if applicable.
     * @param additionalRequestConfig Additional request configuration, if applicable.
     * @returns Data returned from the endpoint.
     * @throws When the request failed.
     */
    protected static async sendRequest<T>(
        controller: string,
        action: string,
        method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
        body?: any,
        additionalRequestConfig?: RequestInit
    ): Promise<T> {
        const url = `${ApiHelper.hostname}/api/${controller}/${action}`;

        let response: Response;

        try {
            response = await fetch(url, {
                ...additionalRequestConfig,
                method,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    ...additionalRequestConfig?.headers,
                },
                body: body ? JSON.stringify(ApiHelper.convertToServerSideTypes(body)) : undefined,
            });
        } catch (error) {
            throw new Error(
                `Failed to perform ${method} '${url}' request (the endpoint may be unreachable, or CORS pre-fetch may have failed authentication); ${error}`
            );
        }

        let content;

        try {
            // Read the content
            content = await response.text();
        } catch (error) {
            throw new Error(`Failed to read the response from the ${method} '${url}' request; ${error}`);
        }

        let jsonContent;

        try {
            // Parse the content as JSON, unless the response is HTTP 204 (No Content)
            jsonContent = content ? JSON.parse(content) : null;
        } catch (error) {
            throw new Error(`Failed to JSON parse the response from the ${method} '${url}' request ${error}`);
        }

        // If the response was not OK
        if (!response.ok) {
            // Throw a specific error (parsing the error object), if possible
            if (this.isError(jsonContent)) {
                throw new RequestError(jsonContent);
            } else {
                throw new RequestError({
                    status: response.status,
                    title: `Response status was not OK; returned HTTP ${response.status} ${response.statusText}`,
                    type: "FetchError",
                    errors: {},
                });
            }
        }

        return ApiHelper.convertToClientSideTypes(jsonContent) as T;
    }

    /**
     * Converts a client-side object into a server-side compatible type.
     * @param content Content to convert.
     * @return Converted content.
     */
    private static convertToServerSideTypes(content: object | undefined | null): object | null {
        if (content === undefined) {
            return null;
        }

        if (content === null || typeof content !== "object" || content instanceof Date) {
            return content;
        }

        if (Array.isArray(content)) {
            return content.map(ApiHelper.convertToServerSideTypes);
        }

        return Object.fromEntries(
            Object.entries(content).map(([key, value]) => [key, ApiHelper.convertToServerSideTypes(value)])
        );
    }

    /**
     * Converts a server-side object into a client-side compatible type.
     * @param content Content to convert.
     * @return Converted content.
     */
    private static convertToClientSideTypes(content: object | undefined | null): any {
        // Match on date strings, examples include:
        //  "2020-01-01T00:00:00"
        //  "2020-01-01T00:00:00Z"
        //  "2020-01-01T00:00:00.000Z"
        const dateRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.?\d{0,3}Z?/i;

        // If the string is a timestamp string
        if (typeof content === "string" && dateRegex.test(content)) {
            return new Date(content);
        }

        if (content === undefined || content === null || typeof content !== "object") {
            return content;
        }

        return Object.fromEntries(
            Object.entries(content).map(([key, value]) => {
                if (Array.isArray(value)) {
                    return [key, value.map(ApiHelper.convertToClientSideTypes)];
                }

                return [key, ApiHelper.convertToClientSideTypes(value)];
            })
        );
    }

    /**
     * Type guard for a response from the server, to test whether it is an error.
     * @param responseContent Content to test.
     * @returns Whether the content is an error.
     */
    private static isError(responseContent: any): responseContent is ErrorResponse {
        const assertedContent = responseContent as ErrorResponse;

        return (
            assertedContent &&
            typeof assertedContent.type === "string" &&
            typeof assertedContent.title === "string" &&
            typeof assertedContent.status === "number" &&
            typeof assertedContent.errors === "object"
        );
    }
}
