import React from "react";
import { Icon, TooltipHost } from "@fluentui/react";
import { useSelector } from "react-redux";
import { FollowUpIcon } from "../../FollowUpIcon";

/**
 * Component representing a field of options.
 *
 * For implementations where the field value state is held within the `data` redux store field, instead use the
 * "subscription wrapper" equivalent component @see DropdownField.
 */
export function BasicDropdownField<T extends string>(props: {
    id: string;
    label: string;
    options: { label: string; value: T }[];
    selectedValue: T | undefined;
    onChange: (selectedOptionValue: T | undefined) => void;
    showValidityMessage: boolean;
    followUpOfPreviousField?: boolean;
    fieldErrors?: string[];
    tooltip?: JSX.Element;
    comboxBoxProps?: React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>;
    labelProps?: React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> & {
        [dataAttribute: string]: any;
    };
}) {
    const isReadOnly = useSelector((store) => store.isReadOnly);

    return (
        <div
            className={`field dropdown-field${props.fieldErrors && props.fieldErrors.length > 0 ? " invalid" : ""}`}
            data-field-id={props.id}
            data-field-label={props.label}
        >
            <label
                className={`field-label${props.comboxBoxProps?.required ? " required" : ""}`}
                htmlFor={props.id}
                {...props.labelProps}
            >
                {props.followUpOfPreviousField && <FollowUpIcon />}
                {props.label}
                {props.tooltip && (
                    <TooltipHost content={props.tooltip} id={`${props.id}-tooltip`}>
                        <Icon
                            iconName="Info"
                            aria-describedby={`${props.id}-tooltip`}
                            style={{ marginLeft: "8px", cursor: "default" }}
                        />
                    </TooltipHost>
                )}
            </label>
            {isReadOnly ? (
                <span className="field-input">{props.selectedValue}</span>
            ) : (
                <select
                    id={props.id}
                    className="field-input"
                    placeholder="Select an option"
                    onChange={(e) => props.onChange(e.target.value as T)}
                    required={true}
                    value={props.selectedValue ?? ""}
                    aria-required={true}
                    {...props.comboxBoxProps}
                >
                    <option value="" disabled></option>
                    {props.options.map((o) => (
                        <option key={o.value} value={o.value}>
                            {o.label}
                        </option>
                    ))}
                </select>
            )}
            {props.fieldErrors && props.fieldErrors.length > 0 && (
                <div id={`${props.id}-error`} className="error" role="alert">
                    {props.fieldErrors
                        .filter((error) => error)
                        .sort((a, b) => a.localeCompare(b))
                        .map((error) => (
                            <div className="error">{error}</div>
                        ))}
                </div>
            )}
        </div>
    );
}
