import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CurrentStageHeader } from "../../Components/CurrentStageHeader";
import { MultiLineStringField } from "../../Components/Fields/MultiLineStringField";
import { StringField } from "../../Components/Fields/StringField";
import { TwoOptionField } from "../../Components/Fields/TwoOptionField";
import { IStage } from "../../Models/IStage";
import "../../Models/Reducers";

/** Description of the second part of the "Personal Details" stage */
export const PersonalDetailsPart2Credentials: IStage = {
    title: "Personal details",
    uniqueStageName: "Personal Details (Part 2; Credentials)",
    fieldNames: [
        "hasOwnTransport",
        "holdsCurrentDrivingLicence",
        "hasDrivingEndorsements",
        "drivingEndorsementSummary",
        "hasUnspentCriminalConvictions",
        "unspentCriminalConvictionsSummary",
        "dbsCheckConsent",
        "requiresInterviewAdjustments",
        "interviewAdjustmentsSummary",
    ],

    /** Component representing the "Personal Details" stage page two */
    component: function PersonalDetailsPart2Credentials(): JSX.Element {
        const dispatch = useDispatch();
        const hasDrivingEndorsements = useSelector((store) => store.data.hasDrivingEndorsements);
        const hasUnspentCriminalConvictions = useSelector((store) => store.data.hasUnspentCriminalConvictions);
        const requiresInterviewAdjustments = useSelector((store) => store.data.requiresInterviewAdjustments);

        // Wipe any `drivingEndorsementSummary` value when `hasDrivingEndorsements` is set `false
        useEffect(() => {
            if (!hasDrivingEndorsements) {
                dispatch({ type: "UpdateData", partialData: { drivingEndorsementSummary: undefined } });
            }
        }, [dispatch, hasDrivingEndorsements]);

        // Wipe any `unspentCriminalConvictionsSummary` value when `hasUnspentCriminalConvictions` is set `false
        useEffect(() => {
            if (!hasUnspentCriminalConvictions) {
                dispatch({ type: "UpdateData", partialData: { unspentCriminalConvictionsSummary: undefined } });
            }
        }, [dispatch, hasUnspentCriminalConvictions]);

        // Wipe any `interviewAdjustmentsSummary` value when `requiresInterviewAdjustments` is set `false
        useEffect(() => {
            if (!requiresInterviewAdjustments) {
                dispatch({ type: "UpdateData", partialData: { interviewAdjustmentsSummary: undefined } });
            }
        }, [dispatch, requiresInterviewAdjustments]);

        return (
            <>
                <CurrentStageHeader />
                <TwoOptionField
                    fieldName="hasOwnTransport"
                    label="Do you have access to your own transport?"
                    trueOptionLabel="Yes"
                    falseOptionLabel="No"
                    checkboxProps={{ required: true }}
                />
                <TwoOptionField
                    fieldName="holdsCurrentDrivingLicence"
                    label="Do you hold a current full UK driving licence?"
                    trueOptionLabel="Yes"
                    falseOptionLabel="No"
                    checkboxProps={{ required: true }}
                />
                <TwoOptionField
                    fieldName="hasDrivingEndorsements"
                    label="Do you have any driving endorsements?"
                    trueOptionLabel="Yes"
                    falseOptionLabel="No"
                    checkboxProps={{ required: true }}
                />
                {hasDrivingEndorsements && (
                    <StringField
                        fieldName="drivingEndorsementSummary"
                        label="Please specify how many points."
                        inputProps={{ required: true, maxLength: 250 }}
                        followUpOfPreviousField
                    />
                )}
                <TwoOptionField
                    fieldName="hasUnspentCriminalConvictions"
                    label="Do you have any unspent criminal convictions?"
                    trueOptionLabel="Yes"
                    falseOptionLabel="No"
                    checkboxProps={{ required: true }}
                />
                {hasUnspentCriminalConvictions && (
                    <MultiLineStringField
                        fieldName="unspentCriminalConvictionsSummary"
                        label="Please give full details."
                        textAreaProps={{ required: true, maxLength: 2000 }}
                        followUpOfPreviousField
                    />
                )}
                <TwoOptionField
                    fieldName="dbsCheckConsent"
                    label="If successful, all applicants require a DBS check. Do you consent to your details being processed for this purpose?"
                    trueOptionLabel="Yes"
                    falseOptionLabel="No"
                    checkboxProps={{ required: true }}
                />
                <TwoOptionField
                    fieldName="requiresInterviewAdjustments"
                    label="Do you suffer from a disability which may require us to make reasonable adjustments to either the interview process or the location of the interview?"
                    trueOptionLabel="Yes"
                    falseOptionLabel="No"
                    checkboxProps={{ required: true }}
                />
                {requiresInterviewAdjustments && (
                    <MultiLineStringField
                        fieldName="interviewAdjustmentsSummary"
                        label="Please give details below"
                        textAreaProps={{ required: true, maxLength: 2000 }}
                        followUpOfPreviousField
                    />
                )}
            </>
        );
    },
};
