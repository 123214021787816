import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner, SpinnerSize } from "@fluentui/react";
import UAParser from "ua-parser-js";
import CandidateApiHelper from "./Helpers/CandidateApiHelper";
import { ApplicationFormMetadataModificationLock } from "./Models/ApplicationFormMetadataModificationLock";
import { InvalidTokenPage } from "../Shared/Components/ErrorPages/InvalidTokenPage";
import { ApplicationFormWizard } from "./ApplicationFormWizard";
import { Modal } from "../Shared/Components/Modal";
import { ErrorPage } from "../Shared/Components/ErrorPages/ErrorPage";
import { RequestError } from "../Shared/Helpers/ApiHelper";
import { Formatting } from "../Shared/Helpers/Formatting";
import { assertFieldsOwnedBySingleStage, retrieveEarliestInvalidStage } from "../Shared/Models/ApplicationStages";
import { ApplicationFormData } from "../Shared/Models/ApplicationFormData";
import { DataRetrievalState } from "../Shared/Models/DataRetrievalState";

/** Component representing the Candidate Application Form */
export function CandidateApplicationForm() {
    const dispatch = useDispatch();
    const data = useSelector((store) => store.data);
    const token = useSelector((store) => store.token);
    const modificationLock = useSelector((store) => store.modificationLock);

    const lockValid = useSelector((store) => store.modificationLock?.isValid);
    const [dataRetrieval, setDataRetrieval] = useState<DataRetrievalState>({ state: "pending-retrieval" });
    const [lockCreation, setLockCreation] = useState<DataRetrievalState>({
        state: "pending-retrieval",
    });
    const [earliestInvalidStageRetrieval, setEarliestInvalidStageRetrieval] = useState<DataRetrievalState<number>>({
        state: "pending-retrieval",
    });
    const [lockRetrieval, setLockRetrieval] = useState<
        DataRetrievalState<ApplicationFormMetadataModificationLock | null>
    >({
        state: "not-initiated",
    });

    // Fire various network requests when their retrieval state is set to "pending-retrieval"
    useEffect(() => {
        if (!token) {
            return;
        }

        if (dataRetrieval.state === "pending-retrieval") {
            setDataRetrieval({ state: "in-progress" });

            (async () => {
                try {
                    const applicationFormData = await CandidateApiHelper.retrieveApplicationFormData(token);

                    dispatch({ type: "UpdateData", partialData: applicationFormData });
                    setDataRetrieval({ state: "success" });
                } catch (error) {
                    setDataRetrieval({ state: "error", error: error as Error });
                }
            })();
        }

        if (lockCreation.state === "pending-retrieval") {
            (async () => {
                setLockCreation({ state: "in-progress" });

                try {
                    const modificationLock = await CandidateApiHelper.renewModificationLock(token);

                    setLockCreation({ state: "success" });
                    dispatch({
                        type: "SetModificationLock",
                        modificationLock: { identifier: modificationLock.identifier, isValid: true },
                    });
                } catch (error) {
                    setLockCreation({ state: "error", error: error as Error });
                }
            })();
        }

        if (earliestInvalidStageRetrieval.state === "pending-retrieval") {
            (async () => {
                setEarliestInvalidStageRetrieval({ state: "in-progress" });

                try {
                    // Retrieve the persisted current stage hint, as well as determining the earliest invalid stage via validation
                    const [persistedStageIndexHint, earliestInvalidStage] = await Promise.all([
                        CandidateApiHelper.retrieveCurrentStageIndexHint(token),
                        retrieveEarliestInvalidStage(token),
                    ]);

                    // Ensure the earliest of the two calculated stage indexes is used (e.g. in case of schema changes
                    // invalidating previous stages since last persist)
                    const stageIndex = Math.min(earliestInvalidStage.index, persistedStageIndexHint);

                    setEarliestInvalidStageRetrieval({ state: "success" });
                    dispatch({ type: "SetCurrentStageIndex", stageIndex });
                } catch (error) {
                    setEarliestInvalidStageRetrieval({ state: "error", error: error as Error });
                }
            })();
        }

        if (lockRetrieval.state === "pending-retrieval") {
            setLockRetrieval({ state: "in-progress" });

            (async () => {
                try {
                    const modificationLock = await CandidateApiHelper.retrieveModificationLock(token);

                    setLockRetrieval({ state: "success", value: modificationLock });
                } catch (error) {
                    setLockRetrieval({ state: "error", error: error as Error });
                }
            })();
        }
    }, [dispatch, token, dataRetrieval, lockCreation, earliestInvalidStageRetrieval, lockRetrieval]);

    // On invalidation of the modification lock, retrieve the latest modification lock for the error modal
    useEffect(() => {
        if (modificationLock?.isValid === false) {
            setLockRetrieval({ state: "pending-retrieval" });
        }
    }, [modificationLock]);

    // On change of the data, ensure a single stage has declared ownership
    useEffect(() => {
        assertFieldsOwnedBySingleStage(Object.keys(data) as (keyof ApplicationFormData)[]);
    }, [data]);

    // If a token is not specified
    if (!token) {
        return <InvalidTokenPage />;
    }

    if (
        dataRetrieval.state === "error" ||
        lockCreation.state === "error" ||
        earliestInvalidStageRetrieval.state === "error" ||
        lockRetrieval.state === "error"
    ) {
        const isAuthorizationError = (retrieval: DataRetrievalState<any>) =>
            retrieval.state === "error" &&
            retrieval.error instanceof RequestError &&
            retrieval.error.response.status === 401;

        const isAlreadySubmittedError = (retrieval: DataRetrievalState<any>) =>
            retrieval.state === "error" &&
            retrieval.error instanceof RequestError &&
            retrieval.error.response.status === 500 &&
            retrieval.error.response.title === "Application Form has already been submitted";

        // If any of the requests show the token is invalid
        if (
            isAuthorizationError(dataRetrieval) ||
            isAuthorizationError(lockCreation) ||
            isAuthorizationError(earliestInvalidStageRetrieval)
        ) {
            return <InvalidTokenPage />;
        } else if (isAlreadySubmittedError(lockCreation)) {
            return (
                <ErrorPage title="Submission Confirmation">
                    <p className="confirmation-message">Your application has already been submitted successfully.</p>
                </ErrorPage>
            );
        } else {
            // Failed due to some other reason e.g. endpoint unreachable, CORS request failed (in development) etc.
            return (
                <ErrorPage title="Failed to Load">
                    <p className="instruction">Failed to retrieve the Application Form, please try again.</p>
                    <p className="instruction">
                        If the issue persists, please contact the Careers Team at Bistech on{" "}
                        <strong>01202 33 22 66</strong> or <strong>careers@bistech.co.uk</strong>.
                    </p>
                </ErrorPage>
            );
        }
    }

    if (
        lockValid === false &&
        lockCreation.state !== "pending-retrieval" &&
        lockCreation.state !== "in-progress" &&
        lockRetrieval.state === "success" &&
        lockRetrieval.value
    ) {
        const { getDevice, getOS, getBrowser } = new UAParser(lockRetrieval.value.userAgent);

        const device = `${getDevice().vendor ?? ""} ${getDevice().model ?? ""}`.trim();
        const browser = getBrowser().name ?? "";
        const operatingSystem = getOS().name ?? "";
        const obtainedOn = lockRetrieval.value?.obtainedOn ? new Date(lockRetrieval.value.obtainedOn) : null;

        return (
            <>
                <Modal
                    id="existing-modification-lock"
                    ariaDescription="Already open"
                    title="Already open"
                    submitButton={{ label: "Reload" }}
                    body={() => (
                        <>
                            <p>
                                You have a more recent version of the Application Form in another session:
                                <ul>
                                    {obtainedOn && (
                                        <li style={{ textAlign: "left" }}>
                                            Started at: <strong>{Formatting.toDateTimeString(obtainedOn)}</strong>
                                        </li>
                                    )}
                                    {device && (
                                        <li style={{ textAlign: "left" }}>
                                            Device: <strong>{device}</strong>
                                        </li>
                                    )}
                                    {browser && (
                                        <li style={{ textAlign: "left" }}>
                                            Browser: <strong>{browser}</strong>
                                        </li>
                                    )}
                                    {operatingSystem && (
                                        <li style={{ textAlign: "left" }}>
                                            Operating System: <strong>{operatingSystem}</strong>
                                        </li>
                                    )}
                                </ul>
                            </p>
                            <p>To remain using the latest session above, close this tab.</p>
                            <p>
                                To reload the latest version in this tab, and cancel the previous session, click{" "}
                                <strong>Reload</strong> below.
                            </p>
                        </>
                    )}
                    onSubmit={() => {
                        setDataRetrieval({ state: "pending-retrieval" });
                        setLockCreation({ state: "pending-retrieval" });
                    }}
                    onClose={null}
                />
                <ApplicationFormWizard />
            </>
        );
    }

    if (
        dataRetrieval.state !== "success" ||
        lockCreation.state !== "success" ||
        earliestInvalidStageRetrieval.state !== "success"
    ) {
        return (
            <span style={{ display: "flex", margin: "2em" }}>
                <Spinner size={SpinnerSize.small} styles={{ root: { marginRight: "0.5em" } }} />
                <span>Loading...</span>
            </span>
        );
    }

    return <ApplicationFormWizard />;
}
