import React from "react";
import { CurrentStageHeader } from "../../Components/CurrentStageHeader";
import { MultiLineStringField } from "../../Components/Fields/MultiLineStringField";
import { StringField } from "../../Components/Fields/StringField";
import { IStage } from "../../Models/IStage";
import "../../Models/Reducers";

/** Description of the "Present or Last Employer" stage */
export const EmploymentPart2PresentOrLastEmployer: IStage = {
    title: "Present or last employer",
    uniqueStageName: "Employment (Part 2; Present or Last Employer)",
    fieldNames: ["presentOrLastRoleSummary", "presentOrLastRoleAcheivementSummary", "presentOrLastRoleNoticePeriod"],

    /** Component representing the "Present or Last Employer" stage */
    component: function EmploymentPart2PresentOrLastEmployer(): JSX.Element {
        return (
            <>
                <CurrentStageHeader />
                <MultiLineStringField
                    fieldName="presentOrLastRoleSummary"
                    label="Give a brief description of your role (including your place within the organisational structure of your company and the products / services involved)."
                    textAreaProps={{ required: true, maxLength: 2000 }}
                />
                <MultiLineStringField
                    fieldName="presentOrLastRoleAcheivementSummary"
                    label="What is your biggest achievement and your biggest learning opportunity within this role?"
                    textAreaProps={{ required: true, maxLength: 2000 }}
                />
                <StringField
                    fieldName="presentOrLastRoleNoticePeriod"
                    label="What is your notice period?"
                    inputProps={{ required: true, maxLength: 150 }}
                />
            </>
        );
    },
};
