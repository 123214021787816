import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { SuggestionSet } from "../../Shared/Components/Fields/Basic/BasicSearchField";
import CandidateApiHelper from "../Helpers/CandidateApiHelper";

/**
 * Public address finder state, detailing current relevant suggestions and input state
 * for the address field.
 */
export type AddressFinderSuggestions = {
    /** Input state & suggestions for the address field. */
    addressSuggestions: SuggestionSet;
};

/** Represents a single results line from the Loqate lookup service. */
export type LoqateResult = {
    id: string;
    oneLine: string;
    type: string;
    text: string;
    description: string;
};

/**
 * Hook tracks an address input. Fetches appropriate suggestion
 * results to be rendered based on this.
 */
export function useAddressFinder(args: {
    /** Current value of the address */
    address: string;

    /** Setter for the address */
    setAddress: (input: string) => void;

    /** Whether address retrieval should be performed (when `true`), or instead it should be read-only (when `false`) */
    enabled: boolean;
}): AddressFinderSuggestions {
    const { address, setAddress, enabled } = args;

    const [addressInputState, setAddressInputState] = useState<FieldInputStateInternal>({ kind: "need-more-input" });
    const [addressSuggestions, setAddressSuggestions] = useState<LoqateResult[]>([]);
    const [addressLoading, setAddressLoading] = useState(false);
    const token = useSelector((store) => store.token);

    const addressCaller = useRef(new AddressCaller());

    /** Updates the set of suggestions for a field */
    async function updateSuggestionSet(input: string, container?: string): Promise<void> {
        if (!token) {
            throw new Error(`Attempted to retrieve search results without first obtaining an authentication token`);
        }

        setAddressLoading(true);

        const { results, isLatestResult, otherRequestsInFlight } = await addressCaller.current.retrieveSuggestions(
            token,
            input,
            container
        );

        if (isLatestResult) {
            setAddressSuggestions(results);
        }

        setAddressLoading(otherRequestsInFlight);
    }

    // Set address suggestions
    useEffect(() => {
        if (addressInputState.kind === "suggestion-selected" && addressInputState.selected === address) {
            return;
        }

        // If the address searching should not be enabled
        if (!enabled) {
            // Do not attempt to retrieve any address results i.e. keep the results read-only
            return;
        }

        if (address !== "") {
            setAddressInputState({ kind: "showing" });
            updateSuggestionSet(address);
        }
    }, [address, addressInputState.kind]);

    let addressSuggestionSet: SuggestionSet;

    switch (addressInputState.kind) {
        case "need-more-input":
            addressSuggestionSet = { kind: "need-more-input" };
            break;

        case "showing":
            addressSuggestionSet = {
                kind: "showing",
                suggestions: addressSuggestions.map((s) => s.oneLine),
                selectSuggestion: (index) => {
                    if (index < 0 || index >= addressSuggestions.length) {
                        throw new Error(`Invalid address selection index ${index}`);
                    }

                    const selectedSuggestion = addressSuggestions[index];

                    // If the selected suggestion is not an Address,
                    // update the suggestions based on the picked entry
                    if (selectedSuggestion.type !== "Address") {
                        updateSuggestionSet(selectedSuggestion.text, selectedSuggestion.id);
                        return;
                    }

                    setAddress(selectedSuggestion.oneLine);
                    setAddressInputState({ kind: "suggestion-selected", selected: selectedSuggestion.oneLine });
                },
                loadingMore: addressLoading,
            };
            break;
        case "suggestion-selected":
            addressSuggestionSet = { kind: "suggestion-selected" };
            break;
    }

    return {
        addressSuggestions: addressSuggestionSet,
    };
}

/** Represents An API handle, storing the time an api call has been made. */
type ApiRequestHandle = {
    requestedTime: Date;
};

/** A caller for retrieval of address suggestions by the content of the address field. */
class AddressCaller {
    cachedResults: Map<string, LoqateResult[]> = new Map();
    mostCurrentResolvedRequest: ApiRequestHandle | null = null;
    inFlightRequests: Map<string, { requestHandle: ApiRequestHandle; retrieve: Promise<LoqateResult[]> }> = new Map();

    /**
     * Retrieves the latests set of suggestisons for the Address field.
     * @param candidateToken Candidate-facing token for authentication.
     * @param inputFieldContents Search string.
     * @param container Id of a parent result, for narrower results searching (optional).
     * @returns Address results, and associated metadata.
     */
    async retrieveSuggestions(
        candidateToken: string,
        inputFieldContents: string,
        container: string = ""
    ): Promise<{ results: LoqateResult[]; isLatestResult: boolean; otherRequestsInFlight: boolean }> {
        const cacheKey = `${inputFieldContents}${container}`;

        if (this.cachedResults.has(cacheKey)) {
            const results = this.cachedResults.get(cacheKey)!;

            return { results, isLatestResult: true, otherRequestsInFlight: this.inFlightRequests.size > 0 };
        } else {
            const requestHandle = { requestedTime: new Date() };

            let results: LoqateResult[];

            if (!this.inFlightRequests.has(cacheKey)) {
                const retrieve = CandidateApiHelper.findAddresses(candidateToken, inputFieldContents, container);

                this.inFlightRequests.set(cacheKey, { requestHandle, retrieve });

                results = await retrieve;

                this.cachedResults.set(cacheKey, await retrieve);
                this.inFlightRequests.delete(cacheKey);
            } else {
                results = await this.inFlightRequests.get(cacheKey)!.retrieve;
            }

            const isLatestResult =
                this.mostCurrentResolvedRequest === null ||
                this.mostCurrentResolvedRequest.requestedTime < requestHandle.requestedTime;

            if (isLatestResult) {
                this.mostCurrentResolvedRequest = requestHandle;
            }

            return { results, isLatestResult, otherRequestsInFlight: this.inFlightRequests.size > 0 };
        }
    }
}

/** Type of the internal state of an address input field. */
type FieldInputStateInternal =
    | { kind: "need-more-input" }
    | { kind: "showing" }
    | { kind: "suggestion-selected"; selected: string };
