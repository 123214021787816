import React from "react";
import { Icon, TooltipHost } from "@fluentui/react";
import { useSelector } from "react-redux";
import { FollowUpIcon } from "../../FollowUpIcon";
import { CheckMarkIcon } from "../../CheckMarkIcon";

/** Component representing an optional checkbox. */
export function BasicOptionalCheckboxField(props: {
    id: string;
    label: string;
    value: boolean;
    onChange: (value: boolean) => void;
    showValidityMessage: boolean;
    followUpOfPreviousField?: boolean;
    tooltip?: JSX.Element;
    inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
    labelProps?: React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> & {
        [dataAttribute: string]: any;
    };
}) {
    const isReadOnly = useSelector((store) => store.isReadOnly);

    return (
        <div
            className="field optional-checkbox-field"
            data-checked={isReadOnly ? props.value : undefined}
            data-field-id={props.id}
            data-field-label={props.label}
        >
            <label className="field-label" htmlFor={props.id} {...props.labelProps}>
                {props.followUpOfPreviousField && <FollowUpIcon />}
                {props.label}
                {props.tooltip && (
                    <TooltipHost content={props.tooltip} id={`${props.id}-tooltip`}>
                        <Icon
                            iconName="Info"
                            aria-describedby={`${props.id}-tooltip`}
                            style={{ marginLeft: "8px", cursor: "default" }}
                        />
                    </TooltipHost>
                )}
            </label>
            {isReadOnly ? (
                <span className="read-only-checkbox">{props.value && <CheckMarkIcon />}</span>
            ) : (
                <input
                    id={props.id}
                    type="checkbox"
                    required={true}
                    className="field-input"
                    checked={props.value === true}
                    onChange={(e) => props.onChange(e.target.checked)}
                    {...props.inputProps}
                />
            )}
        </div>
    );
}
