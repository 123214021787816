/** Helper class for useful utilities. */
export class UtilityHelper {
    /**
     * Returns a function that only executes after not being invoked after N milliseconds
     * @param func - The function to execute
     * @param wait - Milliseconds to wait before allowing the invoke of the given function
     * @param immediate - Fire the function immediately when first invoked
     * @returns The time limited event function
     */
    public static debounce(func: Function, wait = 300, immediate = true): Function {
        let timeout: NodeJS.Timer | null = null;
        const args = arguments;

        const later = () => {
            timeout = null;
            if (!immediate) {
                func.apply(this, args);
            }
        };

        return function (this: Function) {
            const callNow = immediate && !timeout;

            if (timeout) {
                clearTimeout(timeout);
            }

            timeout = setTimeout(later, wait);
            if (callNow) {
                func.apply(this, arguments);
            }
        };
    }
}
