import React from "react";
import { useSelector } from "react-redux";
import { IListModalProps, BasicList } from "./Basic/BasicList";
import {
    ApplicationFormData,
    IEducationEntry,
    IEmploymentEntry,
    ITrainingEntry,
} from "../../Models/ApplicationFormData";
import { getArrayFieldAccessor, getFieldErrorsAccessor } from "./FieldAccessors";
import { getStage } from "../../Models/ApplicationStages";

/**
 * Component representing an editable list of entries, with integrated "add", "edit" and "remove" functionality. 
 *
 * This is a "subscription wrapper" around @see BasicList, designed for array-based Application Form fields in the 
 * `data` redux store field. The main aim of the "subscription wrapper" elements is a reduction in duplication and 
 * complexity for the majority of array-based field component use-cases.
 * 
 * Instead, use @see BasicList if the state for the intended field is not in the `data` redux store field.
 *
 * The additional behaviour this component has over the equivalent "Basic" component includes:
 * - Subscribes to a field in the central `data` redux store, reading only; writing is left to the modal implementation,
 *    specified via the `modalProps` property.
 * - Subscribes to server-side validation errors for this field, passing them into the `fieldErrors` prop, and de-duping 
 *    them from errors messages of the `additionalConstraints` of the current stage.
 */
export function List<T extends IEducationEntry | IEmploymentEntry | ITrainingEntry>(props: {
    fieldName: keyof ApplicationFormData;

    /** Properties of the "Add", "Edit" and "Remove" modals */
    modalProps: IListModalProps<T>;

    /** Headings of the list, and how to render each field */
    headings: { name: string; fieldName: keyof T; renderField: (row: Partial<T>) => JSX.Element }[];

    /** Getter for the user-facing title of the row */
    getRowTitle: (row: T) => string;
}) {
    const fieldGetter = getArrayFieldAccessor<T>(props.fieldName);
    const fieldErrorsGetter = getFieldErrorsAccessor(props.fieldName);
    const rows = useSelector((store) => fieldGetter(store.data));
    const currentStageIndex = useSelector((store) => store.currentStageIndex!);
    const stageAdditionalConstraintErrors = getStage(currentStageIndex).additionalConstraints?.map(
        (constraint) => constraint.errorMessage
    );
    const fieldErrors = useSelector((store) => fieldErrorsGetter(store.serverSideValidationErrors)).map((error) => ({
        ...error,
        // Filter out errors already reported as an additional stage constraint
        errors: error.errors.filter((errorMessage) => !stageAdditionalConstraintErrors?.includes(errorMessage)),
    }));

    return (
        <BasicList
            id={props.fieldName}
            headings={props.headings}
            rows={rows || []}
            getRowTitle={props.getRowTitle}
            modalProps={props.modalProps}
            fieldErrors={fieldErrors}
        />
    );
}
