import React from "react";
import { IStage } from "../../Models/IStage";
import { MultiLineStringField } from "../../Components/Fields/MultiLineStringField";
import "../../Models/Reducers";

/** Description of the "Other Skills & Training" sub-stage of the Training stage */
export const TrainingPart2AdditionalSkills: IStage = {
    title: "Other skills & training",
    uniqueStageName: "Training (Part 2; Additional Skills)",
    fieldNames: ["additionalSkillsAndTrainingSummary"],

    /** Component representing the "Other Skills & Training" sub-stage */
    component: function TrainingPart2AdditionalSkills(): JSX.Element {
        return (
            <>
                <MultiLineStringField
                    fieldName="additionalSkillsAndTrainingSummary"
                    label="What other skills and training do you have? (eg, languages, computer skills etc.)"
                    textAreaProps={{ required: false, maxLength: 2000 }}
                />
            </>
        );
    },
};
