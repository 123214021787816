/** Component representing a generic error page */
export function ErrorPage(props: {
    /** Page heading */
    title: string;

    /** Error page content */
    children?: JSX.Element | JSX.Element[];
}) {
    return (
        <section className="form">
            <main className="main">
                <section className="stage error-page">
                    <h1 className="stage-title">{props.title}</h1>
                    {props.children}
                </section>
            </main>
        </section>
    );
}
