import React from "react";
import { CurrentStageHeader } from "../../Components/CurrentStageHeader";
import { MultiLineStringField } from "../../Components/Fields/MultiLineStringField";
import { IStage } from "../../Models/IStage";
import "../../Models/Reducers";

/** Description of the third part of the "Personal Details" stage */
export const PersonalDetailsPart3Holiday: IStage = {
    title: "Personal details",
    uniqueStageName: "Personal Details (Part 3; Holiday)",
    fieldNames: ["unplannedHolidaysSummary", "plannedHolidaysSummary"],

    /** Component representing the "Personal Details" stage page three */
    component: function PersonalDetailsPart3Holiday(): JSX.Element {
        return (
            <>
                <CurrentStageHeader />
                <MultiLineStringField
                    fieldName="unplannedHolidaysSummary"
                    label="How many unplanned days off (ie, with less than 4 weeks’ notice to your employer) have you had in the last year and why?"
                    textAreaProps={{ required: false, maxLength: 2000 }}
                />
                <MultiLineStringField
                    fieldName="plannedHolidaysSummary"
                    label="What planned leave (including holidays and any appointments) do you have in the next 6 months?"
                    textAreaProps={{ required: false, maxLength: 2000 }}
                />
            </>
        );
    },
};
