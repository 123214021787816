import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BasicStringField } from "../../Components/Fields/Basic/BasicStringField";
import { BasicDurationField } from "../../Components/Fields/Basic/BasicDurationField";
import { BasicTwoOptionField } from "../../Components/Fields/Basic/BasicTwoOptionField";
import { ITrainingEntry } from "../../Models/ApplicationFormData";
import { IListModalProps } from "../../Components/Fields/Basic/BasicList";
import { IModalProps } from "../../Components/Modal";

/** Description of the Add or Edit Education modal on the "Training" Stage */
export function TrainingListModal(): IListModalProps<ITrainingEntry> {
    const dispatch = useDispatch();

    const training = useSelector((store) => store.data.training);
    const [institutionName, setInstitutionName] = useState<ITrainingEntry["institutionName"]>();
    const [isFullTime, setIsFullTime] = useState<ITrainingEntry["isFullTime"]>();
    const [from, setFrom] = useState<ITrainingEntry["from"]>();
    const [to, setTo] = useState<ITrainingEntry["to"]>();
    const [details, setDetails] = useState<ITrainingEntry["details"]>();

    /** Event handler for addition of a row */
    function onAddSubmit() {
        // Append a new entry to the array
        const partialData = {
            training: [
                ...(training || []),
                {
                    institutionName: institutionName!,
                    isFullTime: isFullTime!,
                    from: from!,
                    to: to!,
                    details: details!,
                },
            ],
        };

        dispatch({ type: "UpdateData", partialData });
        onResetModal();
    }

    /**
     * Event handler for edit of a row.
     * @param row Edited row.
     * @param index Index of the row in the training array.
     */
    function onEditSubmit(row: ITrainingEntry, index: number) {
        const partialData = {
            training: [...(training || [])],
        };

        // Replace the element at the index
        partialData.training.splice(index, 1, {
            institutionName: institutionName!,
            isFullTime: isFullTime!,
            from: from!,
            to: to!,
            details: details!,
        });

        dispatch({ type: "UpdateData", partialData });
        onResetModal();
    }

    /**
     * Event handler for removal of a row.
     * @param row Deleted row.
     * @param index Index of the row in the training array.
     */
    function onRemoveSubmit(row: ITrainingEntry, index: number) {
        // Remove the element at the index
        const partialData = { training: (training || []).filter((_, i) => i !== index) };

        dispatch({ type: "UpdateData", partialData });
    }

    /** Event handler for reset of the dialog */
    function onResetModal() {
        setInstitutionName(undefined);
        setIsFullTime(undefined);
        setFrom(undefined);
        setTo(undefined);
        setDetails(undefined);
    }

    const modalBody: IModalProps["body"] = (hasBeenSubmitted) => (
        <>
            <BasicStringField
                id="training-institution-name"
                onChange={setInstitutionName}
                label="Name of institution / professional body"
                value={institutionName ?? ""}
                fieldErrors={[...(hasBeenSubmitted && !institutionName ? ["Please complete field"] : [])]}
                showValidityMessage={hasBeenSubmitted}
                inputProps={{ maxLength: 120, required: true }}
            />
            <BasicTwoOptionField
                id="training-type"
                label="Type"
                onChange={setIsFullTime}
                trueOptionLabel="Full time"
                falseOptionLabel="Part time"
                fieldErrors={[...(hasBeenSubmitted && isFullTime === undefined ? ["Please complete field"] : [])]}
                selectedOption={isFullTime}
                showValidityMessage={hasBeenSubmitted}
                labelProps={{ style: { display: "none" } }}
            />
            <BasicDurationField
                id="training-duration"
                from={{
                    label: "From",
                    value: from,
                    fieldErrors: [...(hasBeenSubmitted && !from ? ["Please complete field"] : [])],
                    inputProps: { required: true },
                }}
                to={{
                    label: "To",
                    value: to,
                    fieldErrors: [...(hasBeenSubmitted && !to ? ["Please complete field"] : [])],
                    inputProps: { required: true },
                }}
                onChange={(from, to) => {
                    setFrom(from);
                    setTo(to);
                }}
                fieldErrors={[...(hasBeenSubmitted && from && to && from > to ? ["'From' cannot be after 'To'"] : [])]}
                showValidityMessage={hasBeenSubmitted}
            />
            <BasicStringField
                id="training-details"
                onChange={setDetails}
                label="Details"
                value={details ?? ""}
                showValidityMessage={hasBeenSubmitted}
                fieldErrors={[...(hasBeenSubmitted && !details ? ["Please complete field"] : [])]}
                tooltip={<>Summary of the training or professional membership.</>}
                inputProps={{ maxLength: 250, required: true }}
            />
        </>
    );

    // Modal configuration
    return {
        addModal: {
            title: "Add training",
            submitButton: { label: "Add" },
            onAddSubmit: onAddSubmit,
        },
        editModal: {
            title: "Edit training",
            submitButton: { label: "Save" },
            onEditSubmit: onEditSubmit,
            onEditRequest: (row) => {
                setInstitutionName(row.institutionName);
                setIsFullTime(row.isFullTime);
                setFrom(row.from);
                setTo(row.to);
                setDetails(row.details);
            },
        },
        removeModal: {
            title: "Remove training",
            onRemoveSubmit: onRemoveSubmit,
        },
        body: modalBody,
        onCancel: onResetModal,
        additionalConstraints: [
            { errorMessage: "'From' cannot be after 'To'", isValid: () => !from || !to || from <= to },
        ],
    };
}
