import deepEqual from "deep-equal";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationFormData } from "../../Models/ApplicationFormData";
import { BasicDropdownField } from "./Basic/BasicDropdownField";
import { getFieldErrorMessagesAccessor, getStringFieldAccessor } from "./FieldAccessors";

type DropdownFieldProps = {
    fieldName: keyof ApplicationFormData;
    label: string;
    options: { label: string; value: string }[];
    followUpOfPreviousField?: boolean;
    tooltip?: JSX.Element;
    comboxBoxProps?: React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>;
    labelProps?: React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> & {
        [dataAttribute: string]: any;
    };
};

/**
 * Component representing a field of options.
 *
 * This is a "subscription wrapper" around @see BasicDropdownField, designed for Application Form fields in the `data`
 * redux store field. The main aim of the "subscription wrapper" elements is a reduction in duplication and complexity
 * for the majority of field component use-cases.
 *
 * Instead, use @see BasicDropdownField if the state for the intended field is not in the `data` redux store field.
 *
 * The additional behaviour this component has over the equivalent "Basic" component includes:
 * - Subscribes to a field in the central `data` redux store, both read and write.
 * - Subscribes to server-side validation errors for this field, passing them into the `fieldErrors` prop.
 * - Subscribes to the `showValidityMessage` to determine when to show certain validation errors.
 * - Diffs the current `value` versus the `attemptedValue` to determine whether the server-side errors should be displayed.
 */
export function DropdownField(props: DropdownFieldProps) {
    const dispatch = useDispatch();
    const fieldGetter = getStringFieldAccessor(props.fieldName);
    const fieldErrorMessagesGetter = getFieldErrorMessagesAccessor(props.fieldName);
    const value = useSelector((store) => fieldGetter(store.data));
    const attemptedValue = useSelector((store) => fieldGetter(store.attemptedData));
    const fieldErrors = useSelector((store) => fieldErrorMessagesGetter(store.serverSideValidationErrors));
    const showValidityMessage = useSelector((store) => store.currentStageSubmitted);

    const isRequired = props.comboxBoxProps?.required === true;
    const errors = [
        ...(showValidityMessage && isRequired && !value ? ["Please complete field"] : []),
        ...(value && deepEqual(value, attemptedValue) ? fieldErrors : []),
    ].reduce<string[]>((unique, item) => {
        if (!unique.includes(item)) {
            unique.push(item);
        }

        return unique;
    }, []);

    return (
        <BasicDropdownField
            id={props.fieldName}
            label={props.label}
            onChange={(value) => dispatch({ type: "UpdateData", partialData: { [props.fieldName]: value } })}
            showValidityMessage={showValidityMessage}
            selectedValue={value}
            options={props.options}
            fieldErrors={errors}
            comboxBoxProps={props.comboxBoxProps}
            labelProps={props.labelProps}
            tooltip={props.tooltip}
            followUpOfPreviousField={props.followUpOfPreviousField}
        />
    );
}
