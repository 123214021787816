import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IStage } from "../../Models/IStage";
import { ApplicationFormData } from "../../Models/ApplicationFormData";
import { DropdownField } from "../../Components/Fields/DropdownField";
import { SearchField } from "../../Components/Fields/SearchField";
import { StringField } from "../../Components/Fields/StringField";
import { CurrentStageHeader } from "../../Components/CurrentStageHeader";
import { useAddressFinder } from "../../../CandidateFacing/Hooks/AddressFinder";

const orderedTitleOptions: ApplicationFormData["title"][] = ["Mr", "Mrs", "Miss", "Ms", "Other"];

/**
 * "Personal Details" wizard stage definition.
 */
export const PersonalDetailsPart1BasicInformation: IStage = {
    title: "Personal details",
    uniqueStageName: "Personal Details (Part 1; Basic Information)",
    fieldNames: [
        "positionAppliedFor",
        "seenAdvertised",
        "surname",
        "forenames",
        "title",
        "nationalInsuranceNumber",
        "address",
        "contactNumber",
    ],

    /** Component representing the "Personal Details" stage */
    component: function PersonalDetailsPart1BasicInformation(): JSX.Element {
        const dispatch = useDispatch();
        const addressLine = useSelector((store) => store.data.address);
        const addressFinder = useAddressFinder({
            address: addressLine ?? "",
            setAddress: (input) => dispatch({ type: "UpdateData", partialData: { address: input } }),
            enabled: useSelector((store) => !store.isReadOnly),
        });

        return (
            <div
                className="grid-container"
                style={{
                    gridTemplateAreas: `
                        "positionAppliedFor seenAdvertised"
                        "header header"
                        "forenames surname"
                        "title address"
                        "niNumber contactNumber"
                    `,
                    gridAutoColumns: "1fr 1fr",
                }}
            >
                <div style={{ gridArea: "positionAppliedFor" }}>
                    <StringField
                        fieldName="positionAppliedFor"
                        label="Position applied for"
                        inputProps={{ required: true, maxLength: 200 }}
                    />
                </div>
                <div style={{ gridArea: "seenAdvertised" }}>
                    <StringField
                        fieldName="seenAdvertised"
                        label="Where did you see this position advertised?"
                        inputProps={{ required: true, maxLength: 200 }}
                    />
                </div>
                <div className="stage-sub-header" style={{ gridArea: "header", marginTop: "2em" }}>
                    <CurrentStageHeader />
                </div>
                <div style={{ gridArea: "forenames" }}>
                    <StringField
                        fieldName="forenames"
                        label="Forename(s)"
                        inputProps={{ required: true, maxLength: 250 }}
                    />
                </div>
                <div style={{ gridArea: "surname" }}>
                    <StringField fieldName="surname" label="Surname" inputProps={{ required: true, maxLength: 250 }} />
                </div>
                <div style={{ gridArea: "title" }}>
                    <DropdownField
                        fieldName="title"
                        label="Title"
                        options={orderedTitleOptions.map((title) => ({ label: title, value: title }))}
                        comboxBoxProps={{ required: true }}
                    />
                </div>
                <div style={{ gridArea: "address" }}>
                    <SearchField
                        fieldName="address"
                        label="Address"
                        suggestions={addressFinder.addressSuggestions}
                        searchBoxProps={{ required: true, maxLength: 250 }}
                    />
                </div>
                <div style={{ gridArea: "niNumber" }}>
                    <StringField
                        fieldName="nationalInsuranceNumber"
                        label="National Insurance number"
                        pattern="[a-zA-Z]{2}\s*\d{2}\s*\d{2}\s*\d{2}\s*[ABCDabcd]"
                        patternErrorMessage="National Insurance number must be 2 letters, followed by 6 digits, followed by either 'A', 'B', 'C' or 'D'; see https://www.gov.uk/national-insurance/your-national-insurance-number"
                        tooltip={
                            <>
                                A valid UK National Insurance number.
                                <br />
                                For guidance, see{" "}
                                <a
                                    href="https://www.gov.uk/national-insurance/your-national-insurance-number"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    National Insurance Guide
                                </a>
                                .
                                <br />
                                We require this as proof of right to work in the UK.
                            </>
                        }
                        inputProps={{ required: true }}
                    />
                </div>
                <div style={{ gridArea: "contactNumber" }}>
                    <StringField
                        fieldName="contactNumber"
                        label="Contact number"
                        pattern="^\+44\s?\d{10}|0044\s?\d{10}|0\s?\d{10}"
                        patternErrorMessage="Contact number must start with '0' or '+44' followed by no more than 10 digits with no whitespace"
                        tooltip={<>A valid UK mobile or landline phone number, starts with 0 or +44, no spaces.</>}
                        inputProps={{ required: true, type: "tel" }}
                    />
                </div>
            </div>
        );
    },

    className: "personal-details-stage",
};
