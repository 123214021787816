import ApiHelper from "../../Shared/Helpers/ApiHelper";
import { ApplicationFormData } from "../../Shared/Models/ApplicationFormData";
import { LoqateResult } from "../Hooks/AddressFinder";
import { ApplicationFormMetadataModificationLock } from "../Models/ApplicationFormMetadataModificationLock";

/** Helper class for querying Candidate-facing data */
export default class CandidateApiHelper extends ApiHelper {
    /**
     * Retrieves the Application Form data.
     * @param candidateToken Candidate-facing token for authentication.
     * @returns Application Form data.
     */
    public static retrieveApplicationFormData(candidateToken: string): Promise<ApplicationFormData> {
        return this.sendRequest<ApplicationFormData>("appForm", "data", "GET", undefined, {
            headers: {
                Authorization: `token ${candidateToken}`,
            },
        });
    }

    /**
     * Retrieves the Application Form validation state.
     * @param candidateToken Candidate-facing token for authentication.
     * @param specificFieldsToValidate Which fields to validate.
     */
    public static retrieveApplicationFormValidationState(
        candidateToken: string,
        specificFieldsToValidate?: string[]
    ): Promise<void> {
        return this.sendRequest<void>("appForm", "validationState", "POST", specificFieldsToValidate || [], {
            headers: {
                Authorization: `token ${candidateToken}`,
            },
        });
    }

    /**
     * Saves the partial Application Form data.
     * @param candidateToken Candidate-facing token for authentication.
     * @param partialApplicationFormData Partial data to save.
     * @param modificationLockId Identifier of the obtained modification lock.
     * @returns Latest persisted Application Form data.
     */
    public static saveApplicationFormData(
        candidateToken: string,
        partialApplicationFormData: Partial<ApplicationFormData>,
        modificationLockId: string
    ): Promise<ApplicationFormData> {
        return this.sendRequest<ApplicationFormData>(
            "appForm",
            `data?modificationLockId=${modificationLockId}`,
            "PATCH",
            partialApplicationFormData,
            {
                headers: {
                    Authorization: `token ${candidateToken}`,
                },
            }
        );
    }

    /**
     * Submits the Application Form data.
     * @param candidateToken Candidate-facing token for authentication.
     * @param modificationLockId Identifier of the obtained modification lock.
     */
    public static submitApplicationForm(candidateToken: string, modificationLockId: string): Promise<void> {
        return this.sendRequest<void>("appForm", `submit?modificationLockId=${modificationLockId}`, "POST", undefined, {
            headers: {
                Authorization: `token ${candidateToken}`,
            },
        });
    }

    /**
     * Voids any existing modification lock, and obtains a new modification lock.
     * @param candidateToken Candidate-facing token for authentication.
     * @returns New modification lock.
     */
    public static renewModificationLock(candidateToken: string): Promise<ApplicationFormMetadataModificationLock> {
        return this.sendRequest<ApplicationFormMetadataModificationLock>(
            "appForm",
            "modificationLock",
            "PUT",
            undefined,
            {
                headers: {
                    Authorization: `token ${candidateToken}`,
                },
            }
        );
    }

    /**
     * Retrieves any existing modification lock.
     * @param candidateToken Candidate-facing token for authentication.
     * @returns Modification lock, or `null` if one does not exist.
     */
    public static retrieveModificationLock(
        candidateToken: string
    ): Promise<ApplicationFormMetadataModificationLock | null> {
        return this.sendRequest<ApplicationFormMetadataModificationLock | null>(
            "appForm",
            "modificationLock",
            "GET",
            undefined,
            {
                headers: {
                    Authorization: `token ${candidateToken}`,
                },
            }
        );
    }

    /**
     * Retrieves the persisted current stage index hint.
     * @param candidateToken Candidate-facing token for authentication.
     * @returns Current stage index hint.
     */
    public static async retrieveCurrentStageIndexHint(candidateToken: string): Promise<number> {
        const { stageIndex } = await this.sendRequest<{ stageIndex: number }>(
            "appForm",
            "currentStageIndex",
            "GET",
            undefined,
            {
                headers: {
                    Authorization: `token ${candidateToken}`,
                },
            }
        );

        return stageIndex;
    }

    /**
     * Persists the current stage index.
     * @param candidateToken Candidate-facing token for authentication.
     * @param stageIndex Stage index to persist.
     */
    public static setCurrentStageIndexHint(candidateToken: string, stageIndex: number): Promise<void> {
        return this.sendRequest(
            "appForm",
            "currentStageIndex",
            "PUT",
            { stageIndex },
            {
                headers: {
                    Authorization: `token ${candidateToken}`,
                },
            }
        );
    }

    /**
     * Returns address search results for the specified search string.
     * @param candidateToken Candidate-facing token for authentication.
     * @param text Search string.
     * @param container Id of a parent result, for narrower results searching (optional).
     */
    public static async findAddresses(
        candidateToken: string,
        text: string,
        container?: string
    ): Promise<LoqateResult[]> {
        const response = await this.sendRequest<{ items: LoqateResult[] }>(
            "addressSearch",
            `find?text=${text}&container=${container}`,
            "GET",
            undefined,
            {
                headers: {
                    Authorization: `token ${candidateToken}`,
                },
            }
        );

        return response.items;
    }
}
