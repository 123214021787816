/** Helper class for formatting of data */
export class Formatting {
    /**
     * Returns the date component of the date time.
     * @example "01/01/2020"
     * @param date Date to format.
     * @returns Formatted date string.
     */
    public static toDateString(date: Date): string {
        return date.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });
    }

    /**
     * Returns the date and time string repesentation of the date time.
     * @example "01/01/2020, 16:20:10"
     * @param date Date to format.
     * @returns Formatted date and time string.
     */
    public static toDateTimeString(date: Date): string {
        return date.toLocaleString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "numeric",
            minute: "2-digit",
            second: "2-digit",
        });
    }

    /**
     * Returns a string representation of the monetary value.
     * @example "£15,650"
     * @param poundsSterlingValue Value to format.
     * @returns Formatted money string.
     */
    public static toMoneyString(poundsSterlingValue: number): string {
        return poundsSterlingValue.toLocaleString("en-GB", {
            style: "currency",
            currency: "GBP",
            minimumFractionDigits: 0,
        });
    }
}
