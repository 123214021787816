import React from "react";
import { BasicDateField } from "./BasicDateField";

/** Description of the properties for a date field in the duration composite field */
interface DateFieldProps {
    label: string;
    value: Date | undefined;
    fieldErrors?: string[];
    visible?: boolean;
    tooltip?: JSX.Element;
    inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
    labelProps?: React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> & {
        [dataAttribute: string]: any;
    };
}

/** Component representing a duration between two dates. */
export function BasicDurationField(props: {
    id: string;
    onChange: (fromValue: Date | undefined, toValue: Date | undefined) => void;
    showValidityMessage: boolean;
    from: DateFieldProps;
    to: DateFieldProps;
    fieldErrors?: string[];
}) {
    return (
        <div
            className={`field duration-field${props.fieldErrors && props.fieldErrors.length > 0 ? " invalid" : ""}`}
            aria-invalid={props.fieldErrors && props.fieldErrors.length > 0}
            aria-errormessage={`${props.id}-error`}
        >
            <div className="date-fields">
                {props.from.visible !== false && (
                    <BasicDateField
                        id={`${props.id}-from`}
                        onChange={(fromValue) => props.onChange(fromValue, props.to.value)}
                        label={props.from.label}
                        value={props.from.value}
                        showValidityMessage={props.showValidityMessage}
                        inputProps={props.from.inputProps}
                        labelProps={props.from.labelProps}
                        tooltip={props.from.tooltip}
                        fieldErrors={props.from.fieldErrors}
                    />
                )}
                {props.to.visible !== false && (
                    <BasicDateField
                        id={`${props.id}-to`}
                        onChange={(toValue) => props.onChange(props.from.value, toValue)}
                        label={props.to.label}
                        value={props.to.value}
                        showValidityMessage={props.showValidityMessage}
                        inputProps={props.to.inputProps}
                        labelProps={props.to.labelProps}
                        tooltip={props.to.tooltip}
                        fieldErrors={props.to.fieldErrors}
                    />
                )}
            </div>
            {props.fieldErrors && props.fieldErrors.length > 0 && (
                <div id={`${props.id}-error`} className="error" role="alert">
                    {props.fieldErrors
                        .filter((error) => error)
                        .sort((a, b) => a.localeCompare(b))
                        .map((error) => (
                            <div className="error">{error}</div>
                        ))}
                </div>
            )}
        </div>
    );
}
