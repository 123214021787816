import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AgreementField } from "../Components/Fields/AgreementField";
import { CurrentStageHeader } from "../Components/CurrentStageHeader";
import { DateField } from "../Components/Fields/DateField";
import { StringField } from "../Components/Fields/StringField";
import { IStage } from "../Models/IStage";
import "../Models/Reducers";

/** Description of the "Declaration" stage */
export const Declaration: IStage = {
    title: "Declaration",
    uniqueStageName: "Declaration",
    fieldNames: ["declarationAgreement", "declarationFullName", "declarationDate"],

    /** Component representing the "Declaration" stage */
    component: function Declaration(): JSX.Element {
        const dispatch = useDispatch();

        // Default the declaration date to now, overwriting any previous value
        useEffect(() => {
            dispatch({ type: "UpdateData", partialData: { declarationDate: new Date() } });
        }, [dispatch]);

        return (
            <>
                <CurrentStageHeader />
                <AgreementField
                    fieldName="declarationAgreement"
                    agreementLabel="I have read and understood the above statement."
                    agreementText={
                        <>
                            I declare to the best of my knowledge and belief that the information given in this
                            application is correct. I understand that any false or misleading information given in this
                            application form or in any other documentation completed or verbal statement made during the
                            recruitment process, may render my contract of employment, if I am appointed, liable to
                            termination.
                        </>
                    }
                />
                <StringField
                    fieldName="declarationFullName"
                    label="Signed"
                    inputProps={{ required: true, maxLength: 500, placeholder: "Full Name" }}
                />
                <DateField
                    fieldName="declarationDate"
                    label="Date"
                    inputProps={{ required: true }}
                />
            </>
        );
    },
};
