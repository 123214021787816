import React, { useEffect, useRef, useState } from "react";
import { DefaultButton, Icon, IconButton, PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";
import ProgressBar from "../Shared/Components/ProgressBar";
import { getStage, getStageFromField, numberOfStages } from "../Shared/Models/ApplicationStages";
import { Modal } from "../Shared/Components/Modal";
import { useAutoSave } from "./Hooks/AutoSave";
import { IStage } from "../Shared/Models/IStage";
import CandidateApiHelper from "./Helpers/CandidateApiHelper";
import { Validation } from "../Shared/Helpers/Validation";
import { DataRetrievalState } from "../Shared/Models/DataRetrievalState";

/** Component representing the Application Form Wizard */
export function ApplicationFormWizard() {
    const dispatch = useDispatch();
    const currentStageIndex = useSelector((store) => store.currentStageIndex);
    const serverSideValidationErrors = useSelector((store) => store.serverSideValidationErrors);
    const currentStageSubmitted = useSelector((store) => store.currentStageSubmitted);
    const [modalVisibility, setModalVisibility] = useState<{ kind: "none" } | { kind: "show-submit-confirmation" }>({
        kind: "none",
    });
    const data = useSelector((store) => store.data);
    const token = useSelector((store) => store.token!);
    const modificationLock = useSelector((store) => store.modificationLock!);
    const form = useRef<HTMLFormElement | null>(null);

    const isFirstStage = currentStageIndex === 0;
    const isSubmissionStage = currentStageIndex === numberOfStages() - 2;
    const isFinalStage = currentStageIndex === numberOfStages() - 1;
    const currentStage = currentStageIndex !== null ? getStage(currentStageIndex) : null;
    const previousStage = currentStageIndex !== null && !isFirstStage ? getStage(currentStageIndex - 1) : undefined;
    const nextStage = currentStageIndex !== null && !isFinalStage ? getStage(currentStageIndex + 1) : undefined;
    const [submission, setSubmissionState] = useState<DataRetrievalState>({ state: "not-initiated" });

    const { saveInProgress, isDirty, flushPendingSave } = useAutoSave();

    let invalidStages = serverSideValidationErrors
        .filter(({ errors }) => errors.length > 0)
        .reduce<(IStage & { index: number })[]>((all, { fieldName }) => {
            const stage = getStageFromField(fieldName);

            if (
                stage &&
                (stage.index !== currentStageIndex || currentStageSubmitted) &&
                !all.some((s) => s.uniqueStageName === stage?.uniqueStageName)
            ) {
                all.push(stage!);
            }

            return all;
        }, [])
        .sort((a, b) => a.index - b.index);

    const invalidStagesMessage = invalidStages.length > 0 && (
        <>
            <Icon className="icon" iconName="Error" />
            <span className="save-status-message" title="Click the links to the stages to fix the validation errors.">
                {invalidStages.map((stage, i) => (
                    <>
                        <button
                            className="navigate-to-stage link"
                            type="button"
                            data-unique-stage-name={stage.uniqueStageName}
                            onClick={() => {
                                setModalVisibility({ kind: "none" });
                                dispatch({ type: "SetCurrentStageIndex", stageIndex: stage.index });
                                dispatch({ type: "CurrentStageSubmitted" });
                            }}
                        >
                            {stage.title}
                        </button>
                        <span>
                            {i === invalidStages.length - 2 ? " and " : i !== invalidStages.length - 1 ? ", " : ""}
                        </span>
                    </>
                ))}{" "}
                {invalidStages.length === 1 ? "stage has" : "stages have"} validation errors
            </span>
        </>
    );

    // If this stage has been submitted, has client-side errors and is not already an invalid stage
    if (
        currentStageSubmitted &&
        form.current &&
        currentStage &&
        Validation.getFormValidationErrors(form.current, data, currentStage.additionalConstraints).length > 0 &&
        !invalidStages.some((stage) => stage.index === currentStageIndex)
    ) {
        // Add this current stage as an invalid stage
        invalidStages = [{ ...currentStage, index: currentStageIndex! }, ...invalidStages].sort(
            (a, b) => a.index - b.index
        );
    }

    // Update the page title on stage change
    useEffect(() => {
        if (currentStage) {
            document.title = `${currentStage.title} | Application Form | Bistech`;
        }
    }, [currentStage]);

    // Reset scroll position on stage change
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentStage]);

    // Add an 'Unsaved changes' prompt when saving is pending
    useEffect(() => {
        const clearUnsavedChangesPrompt = () => {
            window.onbeforeunload = null;
        };

        // If saving is in progress or pending
        if (isDirty || saveInProgress || submission.state === "in-progress") {
            // Show an 'Unsaved changes' prompt when attempting to close the window
            window.onbeforeunload = (event: BeforeUnloadEvent) => {
                event.preventDefault();
                event.returnValue = "";
            };
        } else {
            // Else; wipe the 'Unsaved changes' prompt
            clearUnsavedChangesPrompt();
        }

        // On unmount, remove any 'Unsaved changes' prompt
        return clearUnsavedChangesPrompt;
    }, [isDirty, saveInProgress, submission]);

    /** Event handler for submission of the Application Form */
    async function onSubmitApplicationForm() {
        setSubmissionState({ state: "in-progress" });

        try {
            await CandidateApiHelper.submitApplicationForm(token, modificationLock.identifier);
            setSubmissionState({ state: "success" });
            setModalVisibility({ kind: "none" });

            dispatch({ type: "NextStage" });
        } catch (error) {
            try {
                // On error, save the form to highlight any validation errors
                await flushPendingSave();
            } finally {
                setSubmissionState({ state: "error", error: error as Error });
            }
        }
    }

    return (
        <form className="form" onSubmit={(e) => e.preventDefault()} ref={form}>
            <main className="main">
                {currentStage && (
                    <section
                        className={`stage ${currentStage.className ?? ""}`}
                        data-unique-stage-name={currentStage.uniqueStageName}
                    >
                        <currentStage.component />
                        {currentStageSubmitted &&
                            currentStage.additionalConstraints
                                ?.filter((c) => !c.isValid(data))
                                .map((c) => (
                                    <span key={c.errorMessage} className="error" role="alert">
                                        {c.errorMessage}
                                    </span>
                                ))}
                    </section>
                )}
                {modalVisibility.kind === "show-submit-confirmation" && (
                    <Modal
                        id="submit-confirmation"
                        ariaDescription="Submit Application Form"
                        title="Confirm submission"
                        body={() => (
                            <>
                                <p>
                                    Click <strong>Confirm</strong> to submit this form.
                                </p>
                                {submission.state === "error" && (
                                    <div className="error" role="alert">
                                        Failed to submit.{" "}
                                        {invalidStagesMessage ? (
                                            <>
                                                Please resolve the following validation errors:
                                                <p className="save-status">{invalidStagesMessage}</p>
                                            </>
                                        ) : (
                                            <>
                                                Please refresh the page and try again.
                                                <p>Error message: {submission.error.message}</p>
                                            </>
                                        )}
                                        <p className="instruction">
                                            If the issue persists, please contact the Careers Team at Bistech on{" "}
                                            <strong>01202 33 22 66</strong> or <strong>careers@bistech.co.uk</strong>.
                                        </p>
                                    </div>
                                )}
                            </>
                        )}
                        submitButton={
                            submission.state === "in-progress"
                                ? { label: "Saving", props: { disabled: true } }
                                : { label: "Confirm", props: { disabled: false } }
                        }
                        onClose={submission.state !== "in-progress" ? () => setModalVisibility({ kind: "none" }) : null}
                        onSubmit={onSubmitApplicationForm}
                    />
                )}
            </main>
            {!isFinalStage && (
                <div className="save-status">
                    {saveInProgress ? (
                        <>
                            <Spinner className="icon" size={SpinnerSize.small} />
                            <span className="save-status-message">Saving</span>
                        </>
                    ) : invalidStagesMessage ? (
                        invalidStagesMessage
                    ) : isDirty ? (
                        <>
                            <IconButton
                                className="icon"
                                iconProps={{ iconName: "Save" }}
                                styles={{
                                    root: { width: "auto", height: "auto", padding: 0 },
                                    icon: { margin: 0 },
                                }}
                                onClick={async () => {
                                    await flushPendingSave();
                                    dispatch({ type: "CurrentStageSubmitted" });
                                }}
                            />
                            <span className="save-status-message">Unsaved changes</span>
                        </>
                    ) : (
                        <>
                            <Icon className="icon" iconName="CheckMark" />
                            <span className="save-status-message">Saved</span>
                        </>
                    )}
                </div>
            )}
            <footer className="footer">
                {currentStageIndex !== null && (
                    <ProgressBar currentStageIndex={currentStageIndex} totalStages={numberOfStages() - 1} />
                )}
                {!isFinalStage && (
                    <nav className="wizard-buttons" role="navigation" aria-label={`Application Form Stage navigation`}>
                        <DefaultButton
                            styles={{ root: { margin: "0.4em" } }}
                            className="previous-button"
                            title={previousStage && `Previous stage: ${previousStage.title}`}
                            ariaLabel={
                                previousStage
                                    ? `Navigate to the Previous stage: "${previousStage.title}" once complete`
                                    : "No previous stage"
                            }
                            onClick={() => dispatch({ type: "PreviousStage" })}
                            disabled={isFirstStage || submission.state === "in-progress" || !currentStage}
                        >
                            Back
                        </DefaultButton>
                        <PrimaryButton
                            className="next-button"
                            disabled={saveInProgress || submission.state === "in-progress" || !currentStage}
                            styles={{
                                root: { margin: "0.4em", minWidth: "7.2em" },
                            }}
                            title={
                                saveInProgress || submission.state === "in-progress"
                                    ? "Saving"
                                    : isSubmissionStage
                                    ? "Submit"
                                    : nextStage
                                    ? `Next stage: ${nextStage.title}`
                                    : "Loading"
                            }
                            ariaLabel={
                                isSubmissionStage
                                    ? "Submit the form"
                                    : nextStage
                                    ? `Navigate to the Next stage: "${nextStage.title}" once complete`
                                    : "Loading"
                            }
                            onClick={async (e) => {
                                e.preventDefault();
                                dispatch({ type: "CurrentStageSubmitted" });

                                // Get all the client-side validation errors for the current stage
                                const formValidationErrors = form.current
                                    ? Validation.getFormValidationErrors(
                                          form.current,
                                          data,
                                          currentStage!.additionalConstraints
                                      )
                                    : [];

                                if (formValidationErrors.length > 0) {
                                    // Client-side validation errors; prevent next stage/submit
                                    return;
                                }

                                // Save the form
                                const { hasErrors } = await flushPendingSave();

                                if (hasErrors) {
                                    // Server-side validation errors; prevent next stage/submit
                                    return;
                                }

                                if (isSubmissionStage) {
                                    setModalVisibility({ kind: "show-submit-confirmation" });
                                } else {
                                    dispatch({ type: "NextStage" });
                                }
                            }}
                        >
                            {saveInProgress || submission.state === "in-progress"
                                ? "Saving"
                                : isSubmissionStage
                                ? "Submit"
                                : "Continue"}
                        </PrimaryButton>
                    </nav>
                )}
            </footer>
        </form>
    );
}
