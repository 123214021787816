import React from "react";
import { Icon, TooltipHost } from "@fluentui/react";
import { useSelector } from "react-redux";
import { FollowUpIcon } from "../../FollowUpIcon";

/** Component representing a Pounds Sterling money field. */
export function BasicMoneyField(props: {
    id: string;
    label: string;
    poundsSterlingValue: number | undefined;
    onChange: (poundsSterlingValue: number | undefined) => void;
    showValidityMessage: boolean;
    followUpOfPreviousField?: boolean;
    fieldErrors?: string[];
    tooltip?: JSX.Element;
    inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
    labelProps?: React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> & {
        [dataAttribute: string]: any;
    };
}) {
    const isReadOnly = useSelector((store) => store.isReadOnly);

    return (
        <div
            className={`field money-field${props.fieldErrors && props.fieldErrors.length > 0 ? " invalid" : ""}`}
            data-field-id={props.id}
            data-field-label={props.label}
        >
            <label
                className={`field-label${props.inputProps?.required ? " required" : ""}`}
                htmlFor={props.id}
                {...props.labelProps}
            >
                {props.followUpOfPreviousField && <FollowUpIcon />}
                {props.label}
                {props.tooltip && (
                    <TooltipHost content={props.tooltip} id={`${props.id}-tooltip`}>
                        <Icon
                            iconName="Info"
                            aria-describedby={`${props.id}-tooltip`}
                            style={{ marginLeft: "8px", cursor: "default" }}
                        />
                    </TooltipHost>
                )}
            </label>
            <span className="field-input-wrapper">
                <span className="currency-symbol">&pound;</span>
                {isReadOnly ? (
                    <span className="field-input">{props.poundsSterlingValue}</span>
                ) : (
                    <input
                        id={props.id}
                        aria-invalid={props.fieldErrors && props.fieldErrors.length > 0}
                        aria-errormessage={`${props.id}-error`}
                        className="field-input"
                        type="number"
                        required={true}
                        value={props.poundsSterlingValue}
                        onChange={(e) => props.onChange(e.target.valueAsNumber)}
                        min={0}
                        step={1000}
                        {...props.inputProps}
                    />
                )}
            </span>
            {props.fieldErrors && props.fieldErrors.length > 0 && (
                <div id={`${props.id}-error`} className="error" role="alert">
                    {props.fieldErrors
                        .filter((error) => error)
                        .sort((a, b) => a.localeCompare(b))
                        .map((error) => (
                            <div className="error">{error}</div>
                        ))}
                </div>
            )}
        </div>
    );
}
