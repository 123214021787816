import React from "react";
import { AgreementField } from "../Components/Fields/AgreementField";
import { CurrentStageHeader } from "../Components/CurrentStageHeader";
import { IStage } from "../Models/IStage";
import "../Models/Reducers";

/** Description of the "How we use your Personal Data" stage */
export const HowWeUseYourPersonalData: IStage = {
    title: "How we use your personal data",
    uniqueStageName: "How we use your Personal Data",
    fieldNames: ["personalDataAgreement"],

    /** Component representing the "How we use your Personal Data" stage */
    component: function HowWeUseYourPersonalData(): JSX.Element {
        return (
            <>
                <CurrentStageHeader />
                <AgreementField
                    fieldName="personalDataAgreement"
                    agreementLabel="I have read and understood the Candidate Privacy Notice."
                    agreementText={
                        <>
                            Through the course of our recruitment process we (Bistech plc) will, as permitted by law,
                            collect, process and store certain personal information about you. We will only process your
                            Personal Data for purposes relating to the recruitment process and we only keep your
                            Personal Data for the period necessary to fulfil these purposes. We take the security and
                            integrity of your Personal Data very seriously and we want you to be fully informed, so we
                            have provided a Candidate Privacy Notice that you can find here:{" "}
                            <a
                                href="https://bistech.co.uk/privacy-notice/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                https://bistech.co.uk/privacy-notice/
                            </a>
                        </>
                    }
                />
            </>
        );
    },
};
