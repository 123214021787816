import { DefaultButton } from "@fluentui/react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
    ArrayValidationErrorDescription,
    NonArrayValidationErrorDescription,
    ValidationErrorDescription,
} from "../../../Models/ValidationErrorDescription";
import { IModalProps, Modal } from "../../Modal";

/** Description of the props for the Add/Edit modal */
export interface IListModalProps<T> {
    /** Configuration for the "Add" modal */
    addModal: {
        /** Title of the modal */
        title: IModalProps["title"];

        /** Callback to execute on submit of the modal */
        onAddSubmit: () => void;

        /** Description of the submit button */
        submitButton?: IModalProps["submitButton"];

        /** Description of the cancel button */
        cancelButton?: IModalProps["cancelButton"];
    };

    /** Configuration for the "Edit" modal */
    editModal: {
        /** Title of the modal */
        title: IModalProps["title"];

        /** Callback to execute on edit of a row and display of the "Edit" modal */
        onEditRequest: (row: T, index: number) => void;

        /** Callback to execute on submit of the modal */
        onEditSubmit: (row: T, index: number) => void;

        /** Description of the submit button */
        submitButton?: IModalProps["submitButton"];

        /** Description of the cancel button */
        cancelButton?: IModalProps["cancelButton"];
    };

    /** Configuration for the "Remove" modal */
    removeModal: {
        /** Title of the modal */
        title: IModalProps["title"];

        /** Callback to execute on submit of the modal */
        onRemoveSubmit: (row: T, index: number) => void;
    };

    /** Content of the modal, containing the fields to submit */
    body: IModalProps["body"];

    /** Callback on cancel/close of any of the modals  */
    onCancel: () => void;

    /** Additional constratints to apply on "Add" and "Edit" modal submissions */
    additionalConstraints?: IModalProps["additionalConstraints"];
}

/**
 * Component representing an editable list of entries, with integrated "add", "edit" and "remove" functionality.
 *
 * For implementations where the field value state is held within the `data` redux store field, instead use the
 * "subscription wrapper" equivalent component @see List.
 */
export function BasicList<T>(props: {
    /** Unique identifier of the list */
    id: string;

    fieldErrors?: ValidationErrorDescription[];

    /** Properties of the "Add", "Edit" and "Remove" modals */
    modalProps: IListModalProps<T>;

    /** Headings of the list, and how to render each field */
    headings: { name: string; fieldName: keyof T; renderField: (row: Partial<T>) => JSX.Element }[];

    /** Getter for the user-facing title of the row */
    getRowTitle: (row: T) => string;

    /** Data to render */
    rows: T[];
}) {
    const isReadOnly = useSelector((store) => store.isReadOnly);
    const [modalVisiblity, setModalVisiblity] = useState<
        | { kind: "none" }
        | { kind: "show-add-modal" }
        | { kind: "show-remove-modal"; row: T; index: number }
        | { kind: "show-edit-modal"; row: T; index: number }
    >({ kind: "none" });

    // Get the errors relating to the top-level array field
    const topLevelFieldErrors = props.fieldErrors?.filter((error) => error.type === "non-array") as
        | NonArrayValidationErrorDescription[]
        | undefined;

    // Get the errors for elements within the array field
    const arrayElementErrors = props.fieldErrors?.filter((error) => error.type === "array") as
        | ArrayValidationErrorDescription[]
        | undefined;

    // Build the row elements for the table
    const rows = props.rows.map((row, index) => {
        // Find the errors for this row
        const rowErrors = arrayElementErrors?.filter((error) => error.elementIndex === index) || [];

        const onEdit = () => {
            props.modalProps.editModal.onEditRequest(row, index);
            setModalVisiblity({ kind: "show-edit-modal", row, index });
        };

        return (
            <tr key={JSON.stringify(row)}>
                {props.headings.map((heading) => {
                    // Find the errors for this cell
                    const fieldErrors = rowErrors.filter((error) => error.elementFieldName === heading.fieldName);

                    // Build an error tooltip, if there are errors
                    const tooltip =
                        fieldErrors.length > 0
                            ? `Validation errors:\r\n${fieldErrors.flatMap((error) => error.errors).join("\r\n")}`
                            : undefined;

                    return (
                        <td
                            key={heading.name}
                            data-heading={heading.name}
                            title={tooltip}
                            className={fieldErrors.length > 0 ? "invalid" : ""}
                        >
                            {fieldErrors.length > 0 ? (
                                <button className="error link" type="button" onClick={() => onEdit()}>
                                    {heading.renderField(row)}
                                </button>
                            ) : (
                                heading.renderField(row)
                            )}
                        </td>
                    );
                })}
                {!isReadOnly && (
                    <>
                        <td data-heading="Edit">
                            <DefaultButton
                                className="edit-row-btn"
                                iconProps={{ iconName: "Edit" }}
                                title="Edit"
                                aria-label="Edit row"
                                onClick={() => onEdit()}
                            >
                                Edit
                            </DefaultButton>
                        </td>
                        <td data-heading="Remove">
                            <DefaultButton
                                className="remove-row-btn"
                                iconProps={{ iconName: "Delete" }}
                                title="Remove"
                                aria-label="Remove row"
                                onClick={() => setModalVisiblity({ kind: "show-remove-modal", row, index })}
                            >
                                Remove
                            </DefaultButton>
                        </td>
                    </>
                )}
            </tr>
        );
    });

    return (
        <>
            <section className="list">
                {!isReadOnly && (
                    <DefaultButton
                        className="add-row-btn"
                        iconProps={{ iconName: "Add", styles: { root: { fontSize: "0.9em" } } }}
                        type="button"
                        onClick={() => setModalVisiblity({ kind: "show-add-modal" })}
                    >
                        Add
                    </DefaultButton>
                )}
                {modalVisiblity.kind === "show-add-modal" && (
                    <Modal
                        {...props.modalProps}
                        id={`${props.id}-add-modal`}
                        title={props.modalProps.addModal.title}
                        ariaDescription="Add a new row to the List"
                        submitButton={props.modalProps.addModal.submitButton}
                        cancelButton={props.modalProps.addModal.cancelButton}
                        onSubmit={() => {
                            setModalVisiblity({ kind: "none" });
                            props.modalProps.addModal.onAddSubmit();
                        }}
                        onClose={() => {
                            setModalVisiblity({ kind: "none" });
                            props.modalProps.onCancel();
                        }}
                    />
                )}
                {modalVisiblity.kind === "show-edit-modal" && (
                    <Modal
                        {...props.modalProps}
                        id={`${props.id}-edit-modal`}
                        title={props.modalProps.editModal.title}
                        ariaDescription="Edit a existing row in the List"
                        submitButton={props.modalProps.editModal.submitButton}
                        cancelButton={props.modalProps.editModal.cancelButton}
                        onSubmit={() => {
                            setModalVisiblity({ kind: "none" });
                            props.modalProps.editModal.onEditSubmit(modalVisiblity.row, modalVisiblity.index);
                        }}
                        onClose={() => {
                            setModalVisiblity({ kind: "none" });
                            props.modalProps.onCancel();
                        }}
                    />
                )}
                {modalVisiblity.kind === "show-remove-modal" && (
                    <Modal
                        id={`${props.id}-remove-modal`}
                        title={props.modalProps.removeModal.title}
                        ariaDescription="Remove a row in the List"
                        body={() => <>Are you sure you want to remove this entry?</>}
                        submitButton={{ label: "Remove" }}
                        onSubmit={() => {
                            setModalVisiblity({ kind: "none" });
                            props.modalProps.removeModal.onRemoveSubmit(modalVisiblity.row, modalVisiblity.index);
                        }}
                        onClose={() => setModalVisiblity({ kind: "none" })}
                    />
                )}
                <div className="table">
                    {props.rows.length > 0 && (
                        <table id={props.id}>
                            <thead>
                                <tr>
                                    {props.headings.map((heading) => (
                                        <th key={heading.name} data-heading={heading.name}>
                                            {heading.name}
                                        </th>
                                    ))}
                                    {!isReadOnly && (
                                        <>
                                            <th data-heading="Edit"></th>
                                            <th data-heading="Remove"></th>
                                        </>
                                    )}
                                </tr>
                            </thead>

                            {/* Announce additions immediately with `aria-live=assertive` https://medium.com/@gaurav5430/accessibility-quick-wins-error-messages-with-aria-live-7a622cb606f9 */}
                            <tbody aria-live="assertive">{rows}</tbody>
                        </table>
                    )}
                </div>
            </section>
            {props.fieldErrors && props.fieldErrors.length > 0 && (
                <div id={`${props.id}-error`} className="error" role="alert">
                    {topLevelFieldErrors
                        ?.flatMap((error) => error.errors)
                        .filter((error) => error)
                        .sort((a, b) => a.localeCompare(b))
                        .map((errorMessage) => (
                            <div className="error">{errorMessage}</div>
                        ))}
                    {arrayElementErrors
                        ?.filter((error) => error)
                        .sort((a, b) => a.elementIndex - b.elementIndex)
                        .reduce<string[]>((all, { fieldName, elementIndex, elementFieldName, errors }) => {
                            // Generate a user-facing name of the entry row
                            let entryErrorMessage = `The entry ${
                                props.rows[elementIndex]
                                    ? ` named "${props.getRowTitle(props.rows[elementIndex])}"`
                                    : ` at position ${elementIndex + 1}`
                            } has validation errors`;

                            // If the error does not pertain to a displayed field (and therefore the error is not
                            // displyaed on the table row UI)
                            if (!props.headings.some((heading) => heading.fieldName === fieldName)) {
                                // Append the error messages
                                entryErrorMessage += `: Field '${elementFieldName}' is invalid: ${errors.join(", ")}`;
                            }

                            if (!all.includes(entryErrorMessage)) {
                                all.push(entryErrorMessage);
                            }

                            return all;
                        }, [])
                        .map((entryErrorTitle) => (
                            <div className="error">{entryErrorTitle}</div>
                        ))}
                </div>
            )}
        </>
    );
}
