import { ErrorPage } from "./ErrorPage";

/** Component representing the 'Page not found' error page */
export function PageNotFound(props: {
    /** Suggested paths similar to the page not found */
    suggestedPaths?: { relativePath: string; name: string }[];
    
    /** Additional error content */
    children?: JSX.Element | JSX.Element[];
}) {
    return (
        <ErrorPage title="Page not Found">
            <p className="instruction">
                The page <strong>{window.location.pathname}</strong> does not exist.
            </p>
            <>
                {(props.suggestedPaths?.length ?? 0) > 0 && (
                    <p className="instruction">
                        Did you instead mean one of the following pages:
                        <ul>
                            {props.suggestedPaths?.map(({ relativePath, name }) => (
                                <li key={relativePath}>
                                    <a href={`${relativePath}${window.location.search}`}>{name}</a>
                                </li>
                            ))}
                        </ul>
                    </p>
                )}
            </>
            <>{props.children}</>
        </ErrorPage>
    );
}
