import { Reducer } from "react";
import { DefaultRootState } from "react-redux";
import { Dispatch } from "redux";
import produce, { Draft } from "immer";
import { ApplicationFormData } from "./ApplicationFormData";
import { numberOfStages } from "./ApplicationStages";
import { ValidationErrorDescription } from "./ValidationErrorDescription";

/** Interface detailing the Default Root State for the web app */
declare module "react-redux" {
    interface DefaultRootState {
        /** Current stage, or `null` if not yet retrieved. */
        currentStageIndex: number | null;

        /** Application Form field data */
        data: Partial<ApplicationFormData>;

        /**
         * Application Form data which has been sent to the server-side for saving (irrespective of whether it
         * failed server-side validation).
         */
        attemptedData: Partial<ApplicationFormData>;

        /** Whether the current stage has been submitted */
        currentStageSubmitted: boolean;

        /** Whether the form content should be rendered read-only */
        isReadOnly: boolean;

        /** Candidate-facing or HR-facing auth token to use when making authenticated requests. */
        token: string | null;

        /** Lock identifier for modifying the Application Form, or `null` if not yet retrieved */
        modificationLock: { identifier: string; isValid: boolean } | null;

        /** Server-side validation errors for the currently submitted fields. */
        serverSideValidationErrors: ValidationErrorDescription[];
    }

    // Declare dispatcher to take our root provider's action type
    export function useDispatch(): Dispatch<Action>;
}

/** Initial application state. */
export const initialState: DefaultRootState = {
    data: {},
    attemptedData: {},
    currentStageIndex: null,
    currentStageSubmitted: false,
    isReadOnly: false,
    token: new URLSearchParams(window.location.search).get("token"),
    modificationLock: null,
    serverSideValidationErrors: [],
};

/** Description of all the Actions taken in the web app  */
type Action =
    | { type: "NextStage" }
    | { type: "PreviousStage" }
    | { type: "CurrentStageSubmitted" }
    | { type: "SetAttemptedData"; attemptedData: DefaultRootState["attemptedData"] }
    | { type: "SetReadOnly"; isReadOnly: DefaultRootState["isReadOnly"] }
    | { type: "SetCurrentStageIndex"; stageIndex: DefaultRootState["currentStageIndex"] }
    | { type: "SetModificationLock"; modificationLock: DefaultRootState["modificationLock"] }
    | { type: "ClearServerSideValidationError"; fieldName: string }
    | { type: "SetServerSideValidationErrors"; errors: { serverSideFieldName: string; errors: string[] }[] }
    | { type: "UpdateData"; partialData: DefaultRootState["data"] };

/**
 * Updates the App state from actions
 * @param currentState The current state of the App
 * @param action The action to update the state
 */
export const rootReducer: Reducer<DefaultRootState, Action> = (currentState, action): DefaultRootState =>
    // TODO: update tsconfig/eslint to disallow non-exhaustive switches
    produce<DefaultRootState, Draft<DefaultRootState>>(currentState, (draftState) => {
        switch (action.type) {
            case "CurrentStageSubmitted":
                draftState.currentStageSubmitted = true;
                break;

            case "NextStage": {
                if (draftState.currentStageIndex === numberOfStages() - 1) {
                    throw new Error("Already on last stage");
                } else if (currentState.currentStageIndex === null) {
                    throw new Error("Cannot navigate to Next Stage when currentStageIndex is null");
                } else {
                    draftState.currentStageIndex = currentState.currentStageIndex + 1;
                    draftState.currentStageSubmitted = false;
                }

                break;
            }

            case "PreviousStage": {
                if (draftState.currentStageIndex === 0) {
                    throw new Error(`Already on first stage`);
                } else if (currentState.currentStageIndex === null) {
                    throw new Error("Cannot navigate to Previous Stage when currentStageIndex is null");
                } else {
                    draftState.currentStageSubmitted = false;
                    draftState.currentStageIndex = currentState.currentStageIndex - 1;
                }

                break;
            }

            case "UpdateData":
                draftState.data = { ...currentState.data, ...action.partialData };
                break;

            case "SetModificationLock":
                draftState.modificationLock = action.modificationLock;
                break;

            case "SetReadOnly":
                draftState.isReadOnly = action.isReadOnly;
                break;

            case "SetCurrentStageIndex":
                draftState.currentStageIndex = action.stageIndex;
                break;

            case "SetAttemptedData": {
                draftState.attemptedData = action.attemptedData;
                break;
            }

            case "ClearServerSideValidationError": {
                draftState.serverSideValidationErrors = currentState.serverSideValidationErrors.filter(
                    (error) => error.fieldName !== action.fieldName
                );
                break;
            }

            case "SetServerSideValidationErrors": {
                draftState.serverSideValidationErrors = action.errors.map((validationError) => {
                    // Split the field path description
                    const fieldPaths = validationError.serverSideFieldName
                        .replace("]", "")
                        .split("[")
                        .flatMap((x) => x.split("."));

                    // Normalise required message
                    const errors = validationError.errors?.map((error) =>
                        error.endsWith("field is required.") ? "Please complete field" : error
                    );

                    switch (fieldPaths.length) {
                        case 1: {
                            const [fieldName] = fieldPaths;

                            return {
                                type: "non-array",
                                fieldName: `${fieldName.substring(0, 1).toLowerCase()}${fieldName.substring(
                                    1
                                )}` as keyof ApplicationFormData,
                                errors,
                                rawServerSideFieldName: validationError.serverSideFieldName,
                            };
                        }

                        case 3: {
                            const [fieldName, elementIndex, arrayFieldName] = fieldPaths;

                            return {
                                type: "array",
                                fieldName: `${fieldName.substring(0, 1).toLowerCase()}${fieldName.substring(
                                    1
                                )}` as keyof ApplicationFormData,
                                elementIndex: parseInt(elementIndex),
                                elementFieldName: `${arrayFieldName
                                    .substring(0, 1)
                                    .toLowerCase()}${arrayFieldName.substring(1)}`,
                                errors,
                                rawServerSideFieldName: validationError.serverSideFieldName,
                            };
                        }

                        default:
                            throw new Error(`Failed to parse '${validationError.serverSideFieldName}' field name`);
                    }
                });

                break;
            }
        }
    });
