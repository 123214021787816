import React from "react";
import { initialisePage } from "./App";
import { CandidateApplicationForm } from "./CandidateFacing/CandidateApplicationForm";
import { PageNotFound } from "./Shared/Components/ErrorPages/PageNotFound";
import "./CandidateFacing/Styles/CandidateFacing.scss";

/** Gets the page to display. */
const page: JSX.Element = (() => {
    switch (window.location.pathname.toLowerCase()) {
        case "/":
            return <CandidateApplicationForm />;

        default:
            return <PageNotFound suggestedPaths={[{ relativePath: "/", name: "Application Form" }]} />;
    }
})();

// Render the page
initialisePage(page);
