import {
    ApplicationFormData,
    IEducationEntry,
    IEmploymentEntry,
    ITrainingEntry,
} from "../../Models/ApplicationFormData";
import { ValidationErrorDescription } from "../../Models/ValidationErrorDescription";

type FieldAccessor<T> = (appForm: Partial<ApplicationFormData>) => T | undefined;
type FieldErrorMessagesAccessor = (errors: ValidationErrorDescription[]) => string[];
type FieldErrorsAccessor = (errors: ValidationErrorDescription[]) => ValidationErrorDescription[];

/**
 * Gets an accessor for field error messages.
 * @param fieldName Name of the field.
 * @returns Accessor for field error messagess.
 */
export function getFieldErrorMessagesAccessor(fieldName: keyof ApplicationFormData): FieldErrorMessagesAccessor {
    return (errors) => errors.filter((error) => error.fieldName === fieldName).flatMap((error) => error.errors);
}

/**
 * Gets an accessor for field errors.
 * @param fieldName Name of the field.
 * @returns Accessor for field errors.
 */
export function getFieldErrorsAccessor(fieldName: keyof ApplicationFormData): FieldErrorsAccessor {
    return (errors) => errors.filter((error) => error.fieldName === fieldName);
}

/**
 * Gets the field accessors for a Date field.
 * @param fieldName Name of the Date field.
 * @returns Field accessors for the Date field.
 */
export function getDateFieldAccessor(fieldName: keyof ApplicationFormData): FieldAccessor<Date> {
    return (appForm) => {
        const value = appForm[fieldName];

        if (value === undefined || value === null) {
            return undefined;
        }

        if (value instanceof Date) {
            return value;
        }

        throw new Error(
            `Expected '${fieldName}' to be a Date, null or undefined; value was '${JSON.stringify(value)}'`
        );
    };
}

/**
 * Gets the field accessors for a string field.
 * @param fieldName Name of the string field.
 * @returns Field accessors for the string field.
 */
export function getStringFieldAccessor(fieldName: keyof ApplicationFormData): FieldAccessor<string> {
    return (appForm) => {
        const value = appForm[fieldName];

        if (value === undefined || value === null) {
            return undefined;
        }

        if (typeof value === "string") {
            return value;
        }

        throw new Error(
            `Expected '${fieldName}' to be a string, null or undefined; value was '${JSON.stringify(value)}'`
        );
    };
}

/**
 * Gets the field accessors for a Mandatory Agreement field.
 * @param fieldName Name of the Mandatory Agreement field.
 * @returns Field accessors for the Mandatory Agreement field.
 */
export function getAgreementFieldAccessor(fieldName: keyof ApplicationFormData): FieldAccessor<true> {
    return (appForm) => {
        const value = appForm[fieldName];

        if (value === undefined || value === null) {
            return undefined;
        }

        if (value === true) {
            return true;
        }

        throw new Error(`Expected '${fieldName}' to be true, null or undefined; value was '${JSON.stringify(value)}'`);
    };
}

/**
 * Gets the field accessors for a boolean field.
 * @param fieldName Name of the boolean field.
 * @returns Field accessors for the boolean field.
 */
export function getBooleanFieldAccessor(fieldName: keyof ApplicationFormData): FieldAccessor<boolean> {
    return (appForm) => {
        const value = appForm[fieldName];

        if (value === undefined || value === null) {
            return undefined;
        }

        if (typeof value === "boolean") {
            return value;
        }

        throw new Error(
            `Expected '${fieldName}' to be a boolean, null or undefined; value was '${JSON.stringify(value)}'`
        );
    };
}

/**
 * Gets the field accessors for the array field.
 * @param fieldName Name of the array field.
 * @returns Field accessors for the array field.
 */
export function getArrayFieldAccessor<T extends IEducationEntry | IEmploymentEntry | ITrainingEntry>(
    fieldName: keyof ApplicationFormData
): FieldAccessor<T[]> {
    return (appForm) => {
        const value = appForm[fieldName];

        if (value === undefined || value === null) {
            return undefined;
        }

        if (Array.isArray(value)) {
            return value as T[];
        }

        throw new Error(`Expected '${fieldName}' to be an array; value was '${JSON.stringify(value)}'`);
    };
}
