import React from "react";
import { CurrentStageHeader } from "../Components/CurrentStageHeader";
import { IStage } from "../Models/IStage";
import "../Models/Reducers";

/** Description of the "Successful Submission" confirmation page */
export const SubmissionConfirmation: IStage = {
    title: "Submission confirmation",
    uniqueStageName: "Submission Confirmation",
    fieldNames: [],

    /** Component representing the "Successful Submission" stage */
    component: function SubmissionConfirmation(): JSX.Element {
        return (
            <>
                <CurrentStageHeader />
                <p className="confirmation-message">
                    Your application was submitted successfully and you can now safely close this window.
                </p>
            </>
        );
    },
};
