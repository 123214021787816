import React from "react";
import { Icon, TooltipHost } from "@fluentui/react";
import { useSelector } from "react-redux";
import { FollowUpIcon } from "../../FollowUpIcon";
import { CheckMarkIcon } from "../../CheckMarkIcon";

/**
 * Component representing a mandatory agreement.
 *
 * For implementations where the field value state is held within the `data` redux store field, instead use the
 * "subscription wrapper" equivalent component @see AgreementField.
 */
export function BasicAgreementField(props: {
    id: string;
    agreementText: JSX.Element;
    agreementLabel: string;
    agreementValue: true | undefined;
    onChange: (agreementValue: true | undefined) => void;
    showValidityMessage: boolean;
    followUpOfPreviousField?: boolean;
    fieldErrors?: string[];
    tooltip?: JSX.Element;
    checkboxProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
    labelProps?: React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> & {
        [dataAttribute: string]: any;
    };
}) {
    const isReadOnly = useSelector((store) => store.isReadOnly);

    return (
        <div
            className={`field agreement-field${props.fieldErrors && props.fieldErrors.length > 0 ? " invalid" : ""}`}
            data-field-id={props.id}
            data-field-label={props.agreementLabel}
        >
            {props.followUpOfPreviousField && <FollowUpIcon />}
            <p className="field-label" aria-label="Agreement">
                {props.agreementText}
            </p>
            <label
                className="agreement-option"
                htmlFor={props.id}
                data-checked={isReadOnly ? props.agreementValue || false : undefined}
                {...props.labelProps}
            >
                {isReadOnly ? (
                    <span className="read-only-checkbox">{props.agreementValue && <CheckMarkIcon />}</span>
                ) : (
                    <input
                        id={props.id}
                        aria-invalid={props.fieldErrors && props.fieldErrors.length > 0}
                        aria-errormessage={`${props.id}-error`}
                        type="checkbox"
                        required={true}
                        className="field-input"
                        checked={props.agreementValue === true}
                        onChange={(e) => props.onChange(e.target.checked || undefined)}
                        {...props.checkboxProps}
                    />
                )}
                {props.agreementLabel}
                {props.tooltip && (
                    <TooltipHost content={props.tooltip} id={`${props.id}-tooltip`}>
                        <Icon
                            iconName="Info"
                            aria-describedby={`${props.id}-tooltip`}
                            style={{ marginLeft: "8px", cursor: "default" }}
                        />
                    </TooltipHost>
                )}
            </label>
            {props.fieldErrors && props.fieldErrors.length > 0 && (
                <div id={`${props.id}-error`} className="error" role="alert">
                    {props.fieldErrors
                        .filter((error) => error)
                        .sort((a, b) => a.localeCompare(b))
                        .map((error) => (
                            <div className="error">{error}</div>
                        ))}
                </div>
            )}
        </div>
    );
}
