import React from "react";
import { Icon, TooltipHost } from "@fluentui/react";
import { useSelector } from "react-redux";
import { FollowUpIcon } from "../../FollowUpIcon";

const DEFAULT_MAX_LENGTH = 2000;

/**
 * Component representing a multi-line text area.
 *
 * For implementations where the field value state is held within the `data` redux store field, instead use the
 * "subscription wrapper" equivalent component @see MultiLineStringField.
 *
 * See @see BasicStringField for single-line fields.
 */
export function BasicMultiLineStringField(props: {
    id: string;
    label: string;
    value: string | undefined;
    onChange: (value: string | undefined) => void;
    showValidityMessage: boolean;
    followUpOfPreviousField?: boolean;
    fieldErrors?: string[];
    tooltip?: JSX.Element;
    textAreaProps?: React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;
    labelProps?: React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> & {
        [dataAttribute: string]: any;
    };
}) {
    const isReadOnly = useSelector((store) => store.isReadOnly);

    return (
        <div
            className={`field multi-line-string-field${
                props.fieldErrors && props.fieldErrors.length > 0 ? " invalid" : ""
            }`}
            data-field-id={props.id}
            data-field-label={props.label}
        >
            <label
                className={`field-label${props.textAreaProps?.required ? " required" : ""}`}
                htmlFor={props.id}
                {...props.labelProps}
            >
                {props.followUpOfPreviousField && <FollowUpIcon />}
                {props.label}
                {props.tooltip && (
                    <TooltipHost content={props.tooltip} id={`${props.id}-tooltip`}>
                        <Icon
                            iconName="Info"
                            aria-describedby={`${props.id}-tooltip`}
                            style={{ marginLeft: "8px", cursor: "default" }}
                        />
                    </TooltipHost>
                )}
            </label>
            {isReadOnly ? (
                <span className="field-input">{props.value}</span>
            ) : (
                <textarea
                    id={props.id}
                    aria-invalid={props.fieldErrors && props.fieldErrors.length > 0}
                    aria-errormessage={`${props.id}-error`}
                    className="field-input"
                    required={true}
                    value={props.value}
                    onChange={(e) => props.onChange(e.target.value)}
                    {...props.textAreaProps}
                    maxLength={props.textAreaProps?.maxLength ?? DEFAULT_MAX_LENGTH}
                />
            )}
            {props.fieldErrors && props.fieldErrors.length > 0 && (
                <div id={`${props.id}-error`} className="error" role="alert">
                    {props.fieldErrors
                        .filter((error) => error)
                        .sort((a, b) => a.localeCompare(b))
                        .map((error) => (
                            <div className="error">{error}</div>
                        ))}
                </div>
            )}
        </div>
    );
}
