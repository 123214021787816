import { DefaultButton, IButtonProps, IconButton, PrimaryButton } from "@fluentui/react";
import { useEffect, useRef, useState } from "react";
import { AdditionalSubmissionConstraint, Validation } from "../Helpers/Validation";

/** Properties of the Modal */
export interface IModalProps {
    /** Globally unique identifier for the modal */
    id: string;

    /** Accessible description of the content of the modal */
    ariaDescription: string;

    /** Heading of the modal */
    title: string;

    /** Description of the 'submit' button */
    submitButton?: {
        label: string;
        props?: IButtonProps;
    };

    /** Description of the 'cancel' button */
    cancelButton?: {
        label: string;
        props?: IButtonProps;
    };

    /** Callback to render the modal body */
    body: (hasBeenSubmitted: boolean) => JSX.Element;

    /** Callback for submit of the modal */
    onSubmit: () => void;

    /** Callback for cancel/closure of the modal, or `null` if no closure should be possible */
    onClose: (() => void) | null;

    /** Additional constraints for validating the form submission */
    additionalConstraints?: AdditionalSubmissionConstraint<null>[];
}

/** Component representing a modal dialog. */
export function Modal(props: IModalProps) {
    const { additionalConstraints, id, title, body, submitButton, cancelButton, ariaDescription, onSubmit, onClose } =
        props;
    const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);
    const form = useRef<HTMLDivElement | null>(null);
    const modalFooter = useRef<HTMLDivElement | null>(null);
    const formValidationErrors = form.current
        ? Validation.getFormValidationErrors(form.current, null, additionalConstraints)
        : [];

    // On first render, focus the first control inside the modal
    // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/dialog_role#Required_JavaScript_features
    useEffect(() => {
        const firstFormControl = form.current?.querySelector<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >("input,select,textarea");

        const firstModalButton = modalFooter.current?.querySelector<HTMLInputElement | HTMLButtonElement>(
            "button,input"
        );

        // Focus the first available form control, or where the modal is not a form (e.g. confirmation), the first button
        (firstFormControl ?? firstModalButton)?.focus();
    }, []);

    // Register a key handler to close the dialog when 'ESC' is pressed
    useEffect(() => {
        const handler = (ev: KeyboardEvent) => {
            if (ev.key === "Escape" && onClose) {
                onClose();
            }
        };

        window.addEventListener("keyup", handler);

        // Unregister the listener on unmount
        return () => window.removeEventListener("keyup", handler);
    }, [onClose]);

    /** Handler to execution on click of the modal 'submit' button */
    function onModalSubmit() {
        setHasBeenSubmitted(true);

        // Determine if the modal form can be submitted
        const formValidationErrors = Validation.getFormValidationErrors(form.current!, null, additionalConstraints);

        if (formValidationErrors.length > 0) {
            // Form invalid; prevent submit
            return;
        }

        onSubmit();
    }

    return (
        <div className="modal-wrapper" id={id}>
            {/* Describe the modal structure for accessibility https://www.w3.org/TR/wai-aria-practices/examples/dialog-modal/dialog.html */}
            <section
                className="modal"
                ref={form}
                onSubmit={(e) => e.preventDefault()}
                role="dialog"
                aria-labelledby={`${id}-title`}
                aria-describedby={`${id}-description`}
            >
                <div className="modal-header">
                    <h3 className="modal-title" id={`${id}-title`}>
                        {title}
                    </h3>
                    {onClose && (
                        <IconButton
                            className="modal-close-btn"
                            iconProps={{ iconName: "Clear" }}
                            title="Close"
                            ariaLabel="Close"
                            onClick={onClose}
                        />
                    )}
                </div>
                <div className="modal-body">
                    <div className="accessibility-description" id={`${id}-description`} style={{ display: "none" }}>
                        {ariaDescription}
                    </div>
                    {hasBeenSubmitted && formValidationErrors.length > 0 && (
                        <div className="error" role="alert">
                            The following error{formValidationErrors.length === 1 ? "" : "s"} prevented submission:
                            <ul className="error-list">
                                {formValidationErrors.map((errorMessage) => (
                                    <li key={errorMessage} className="error-item">
                                        {errorMessage}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    {body(hasBeenSubmitted)}
                </div>
                <div className="modal-footer" ref={modalFooter}>
                    {onClose && (
                        <DefaultButton {...cancelButton?.props} type="button" onClick={() => onClose()}>
                            {cancelButton?.label || "Cancel"}
                        </DefaultButton>
                    )}
                    <PrimaryButton {...submitButton?.props} type="button" onClick={() => onModalSubmit()}>
                        {submitButton?.label || "Submit"}
                    </PrimaryButton>
                </div>
            </section>
        </div>
    );
}
