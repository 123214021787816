import React from "react";
import { ErrorPage } from "./ErrorPage";

/** Component representing the 'Invalid Token' error page */
export function InvalidTokenPage(props: {
    /** Additional error content */
    children?: JSX.Element | JSX.Element[];
}) {
    return (
        <ErrorPage title="Link Invalid">
            <p className="instruction">The Application Form Link is invalid or has expired.</p>
            <p className="instruction">
                Please contact the Careers Team at Bistech on <strong>01202 33 22 66</strong> or{" "}
                <strong>careers@bistech.co.uk</strong>.
            </p>
            <>{props.children}</>
        </ErrorPage>
    );
}
