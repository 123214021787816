import React from "react";

/** Component representing a progress bar of the stages of the Application Form. */
export default function ProgressBar(props: { currentStageIndex: number; totalStages: number }) {
    const progressPercentage = Math.round((props.currentStageIndex / props.totalStages) * 100);

    return (
        <div className="progress-bar">
            <span className="progress-bar-label" aria-label="Application Form progress">
                Application Progress
                {progressPercentage !== 0 && ` (${progressPercentage}%)`}
            </span>
            <div className="progress-bar-total" title={`Stage ${props.currentStageIndex} of ${props.totalStages}`}>
                <div className="progress-bar-progress" style={{ width: `${progressPercentage}%` }}></div>
            </div>
        </div>
    );
}
