import React from "react";
import { getStage } from "../Models/ApplicationStages";
import { useSelector } from "react-redux";

/** Renders the header for the current wizard stage. */
export function CurrentStageHeader(): JSX.Element {
    const currentStageIndex = useSelector((store) => store.currentStageIndex!);
    const currentStage = getStage(currentStageIndex);

    return (
        <h1 className="stage-title" aria-label="Application Form stage">
            {currentStage.title}
        </h1>
    );
}
