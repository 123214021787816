import React from "react";
import { Icon, TooltipHost } from "@fluentui/react";
import { useSelector } from "react-redux";
import { Formatting } from "../../../Helpers/Formatting";
import { FollowUpIcon } from "../../FollowUpIcon";

/**
 * Component representing a date field.
 *
 * For implementations where the field value state is held within the `data` redux store field, instead use the
 * "subscription wrapper" equivalent component @see DateField.
 */
export function BasicDateField(props: {
    id: string;
    label: string;
    value: Date | undefined;
    onChange: (value: Date | undefined) => void;
    showValidityMessage: boolean;
    followUpOfPreviousField?: boolean;
    fieldErrors?: string[];
    tooltip?: JSX.Element;
    inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
    labelProps?: React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> & {
        [dataAttribute: string]: any;
    };
}) {
    const isReadOnly = useSelector((store) => store.isReadOnly);

    return (
        <div
            className={`field date-field${props.fieldErrors && props.fieldErrors.length > 0 ? " invalid" : ""}`}
            data-field-id={props.id}
            data-field-label={props.label}
        >
            <label
                className={`field-label${props.inputProps?.required ? " required" : ""}`}
                htmlFor={props.id}
                {...props.labelProps}
            >
                {props.followUpOfPreviousField && <FollowUpIcon />}
                {props.label}
                {props.tooltip && (
                    <TooltipHost content={props.tooltip} id={`${props.id}-tooltip`}>
                        <Icon
                            iconName="Info"
                            aria-describedby={`${props.id}-tooltip`}
                            style={{ marginLeft: "8px", cursor: "default" }}
                        />
                    </TooltipHost>
                )}
            </label>
            {isReadOnly ? (
                <span className="field-input">{props.value && Formatting.toDateString(props.value)}</span>
            ) : (
                <input
                    id={props.id}
                    aria-invalid={props.fieldErrors && props.fieldErrors.length > 0}
                    aria-errormessage={`${props.id}-error`}
                    className="field-input"
                    type="date"
                    required={true}
                    value={props.value?.toISOString().split("T")[0]}
                    onChange={(e) => props.onChange(e.target.valueAsDate || undefined)}
                    {...props.inputProps}
                />
            )}
            {props.fieldErrors && props.fieldErrors.length > 0 && (
                <div id={`${props.id}-error`} className="error" role="alert">
                    {props.fieldErrors
                        .filter((error) => error)
                        .sort((a, b) => a.localeCompare(b))
                        .map((error) => (
                            <div className="error">{error}</div>
                        ))}
                </div>
            )}
        </div>
    );
}
