import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BasicStringField } from "../../Components/Fields/Basic/BasicStringField";
import { BasicDurationField } from "../../Components/Fields/Basic/BasicDurationField";
import { BasicOptionalCheckboxField } from "../../Components/Fields/Basic/BasicOptionalCheckboxField";
import { BasicMoneyField } from "../../Components/Fields/Basic/BasicMoneyField";
import { IEmploymentEntry } from "../../Models/ApplicationFormData";
import { IListModalProps } from "../../Components/Fields/Basic/BasicList";
import { IModalProps } from "../../Components/Modal";
import { BasicMultiLineStringField } from "../../Components/Fields/Basic/BasicMultiLineStringField";
import { useAddressFinder } from "../../../CandidateFacing/Hooks/AddressFinder";
import { BasicSearchField } from "../../Components/Fields/Basic/BasicSearchField";

/** Description of the Add or Edit Employment modal on the "Employment" Stage */
export function EmploymentListModal(): IListModalProps<IEmploymentEntry> {
    const dispatch = useDispatch();

    const employment = useSelector((store) => store.data.employment);
    const [employerName, setEmployerName] = useState<IEmploymentEntry["employerName"]>();
    const [address, setAddress] = useState<IEmploymentEntry["address"]>();
    const [from, setFrom] = useState<IEmploymentEntry["from"]>();
    const [to, setTo] = useState<IEmploymentEntry["to"]>();
    const [isCurrentRole, setIsCurrentRole] = useState<IEmploymentEntry["isCurrentRole"]>();
    const [jobTitle, setJobTitle] = useState<IEmploymentEntry["jobTitle"]>();
    const [dutiesSummary, setDutiesSummary] = useState<IEmploymentEntry["dutiesSummary"]>();
    const [startingSalary, setStartingSalary] = useState<IEmploymentEntry["startingSalary"]>();
    const [endSalary, setEndSalary] = useState<IEmploymentEntry["endSalary"]>();
    const [reasonForLeavingSummary, setReasonForLeavingSummary] =
        useState<IEmploymentEntry["reasonForLeavingSummary"]>();
    const [reference, setReference] = useState<IEmploymentEntry["reference"]>();
    const addressFinder = useAddressFinder({
        address: address ?? "",
        setAddress: setAddress,
        enabled: useSelector((store) => !store.isReadOnly),
    });

    /** Event handler for addition of a row */
    function onAddSubmit() {
        // Append a new entry to the array
        const partialData = {
            employment: [
                ...(employment || []),
                {
                    employerName: employerName!,
                    address: address!,
                    from: from!,
                    to: to!,
                    isCurrentRole: isCurrentRole || false,
                    dutiesSummary: dutiesSummary!,
                    jobTitle: jobTitle!,
                    startingSalary: startingSalary!,
                    endSalary: endSalary!,
                    reasonForLeavingSummary: reasonForLeavingSummary!,
                    reference: reference!,
                },
            ],
        };

        dispatch({ type: "UpdateData", partialData });
        onResetModal();
    }

    /**
     * Event handler for edit of a row.
     * @param row Edited row.
     * @param index Index of the row in the employment array.
     */
    function onEditSubmit(row: IEmploymentEntry, index: number) {
        const partialData = {
            employment: [...(employment || [])],
        };

        // Replace the element at the index
        partialData.employment.splice(index, 1, {
            employerName: employerName!,
            address: address!,
            from: from!,
            to: to!,
            isCurrentRole: isCurrentRole || false,
            dutiesSummary: dutiesSummary!,
            jobTitle: jobTitle!,
            startingSalary: startingSalary!,
            endSalary: endSalary!,
            reasonForLeavingSummary: reasonForLeavingSummary!,
            reference: reference!,
        });

        dispatch({ type: "UpdateData", partialData });
        onResetModal();
    }

    /**
     * Event handler for removal of a row.
     * @param row Deleted row.
     * @param index Index of the row in the employment array.
     */
    function onRemoveSubmit(row: IEmploymentEntry, index: number) {
        // Remove the element at the index
        const partialData = { employment: (employment || []).filter((_, i) => i !== index) };

        dispatch({ type: "UpdateData", partialData });
    }

    /** Event handler for reset of the dialog */
    function onResetModal() {
        setEmployerName(undefined);
        setAddress(undefined);
        setFrom(undefined);
        setTo(undefined);
        setIsCurrentRole(undefined);
        setDutiesSummary(undefined);
        setJobTitle(undefined);
        setStartingSalary(undefined);
        setEndSalary(undefined);
        setReasonForLeavingSummary(undefined);
        setReference(undefined);
    }

    const modalBody: IModalProps["body"] = (hasBeenSubmitted) => (
        <>
            <BasicStringField
                id="employment-employer-name"
                onChange={setEmployerName}
                label="Name of employer"
                value={employerName ?? ""}
                fieldErrors={[...(hasBeenSubmitted && !employerName ? ["Please complete field"] : [])]}
                showValidityMessage={hasBeenSubmitted}
                inputProps={{ required: true, maxLength: 120 }}
            />
            <BasicSearchField
                id="employment-employer-address"
                onChange={setAddress}
                label="Address"
                value={address ?? ""}
                suggestions={addressFinder.addressSuggestions}
                fieldErrors={[...(hasBeenSubmitted && !address ? ["Please complete field"] : [])]}
                showValidityMessage={hasBeenSubmitted}
                searchBoxProps={{ required: true, maxLength: 250 }}
            />
            <BasicOptionalCheckboxField
                id="employment-current-role"
                label="Current role"
                onChange={(isCurrentRole) => {
                    setIsCurrentRole(isCurrentRole);

                    // If this is a current role
                    if (isCurrentRole) {
                        // Wipe the 'To' date field
                        setTo(undefined);
                    }
                }}
                value={isCurrentRole === true}
                showValidityMessage={hasBeenSubmitted}
                inputProps={{ required: false }}
            />
            <BasicDurationField
                id="employment-duration"
                from={{
                    label: "From",
                    value: from,
                    fieldErrors: [...(hasBeenSubmitted && !from ? ["Please complete field"] : [])],
                    inputProps: { required: true },
                }}
                to={{
                    label: "To",
                    value: to,
                    visible: !isCurrentRole,
                    fieldErrors: [...(hasBeenSubmitted && !isCurrentRole && !to ? ["Please complete field"] : [])],
                    inputProps: { required: !isCurrentRole },
                }}
                onChange={(from, to) => {
                    setFrom(from);
                    setTo(to);
                }}
                fieldErrors={[...(hasBeenSubmitted && from && to && from > to ? ["'From' cannot be after 'To'"] : [])]}
                showValidityMessage={hasBeenSubmitted}
            />
            <BasicStringField
                id="employment-job-title"
                onChange={setJobTitle}
                label="Job title"
                value={jobTitle ?? ""}
                fieldErrors={[...(hasBeenSubmitted && !jobTitle ? ["Please complete field"] : [])]}
                showValidityMessage={hasBeenSubmitted}
                inputProps={{ required: true, maxLength: 120 }}
            />
            <BasicMultiLineStringField
                id="employment-duties-summary"
                onChange={setDutiesSummary}
                label="Describe your duties"
                value={dutiesSummary}
                fieldErrors={[...(hasBeenSubmitted && !dutiesSummary ? ["Please complete field"] : [])]}
                showValidityMessage={hasBeenSubmitted}
                textAreaProps={{ required: true, maxLength: 2000 }}
            />
            <div className="form-row">
                <BasicMoneyField
                    id="employment-starting-salary"
                    onChange={setStartingSalary}
                    label="Starting salary"
                    poundsSterlingValue={startingSalary}
                    fieldErrors={[
                        ...(hasBeenSubmitted && !startingSalary && startingSalary !== 0
                            ? ["Please complete field"]
                            : []),
                    ]}
                    showValidityMessage={hasBeenSubmitted}
                    inputProps={{ required: true }}
                />
                <BasicMoneyField
                    id="employment-end-salary"
                    onChange={setEndSalary}
                    label="End salary"
                    poundsSterlingValue={endSalary}
                    fieldErrors={[
                        ...(hasBeenSubmitted && !endSalary && endSalary !== 0 ? ["Please complete field"] : []),
                    ]}
                    showValidityMessage={hasBeenSubmitted}
                    inputProps={{ required: true }}
                />
            </div>
            <BasicStringField
                id="employment-reason-for-leaving-summary"
                onChange={setReasonForLeavingSummary}
                label="Reason for leaving"
                value={reasonForLeavingSummary ?? ""}
                fieldErrors={[...(hasBeenSubmitted && !reasonForLeavingSummary ? ["Please complete field"] : [])]}
                showValidityMessage={hasBeenSubmitted}
                inputProps={{ required: true, maxLength: 250 }}
            />
            <BasicStringField
                id="employment-reference"
                onChange={setReference}
                label="Apply for reference to"
                value={reference ?? ""}
                showValidityMessage={hasBeenSubmitted}
                inputProps={{ required: false, maxLength: 120 }}
                tooltip={<>Full name, contact number / email address for reference.</>}
            />
        </>
    );

    // Modal configuration
    return {
        addModal: {
            title: "Add employment",
            submitButton: { label: "Add" },
            onAddSubmit: onAddSubmit,
        },
        editModal: {
            title: "Edit employment",
            submitButton: { label: "Save" },
            onEditSubmit: onEditSubmit,
            onEditRequest: (row) => {
                setEmployerName(row.employerName);
                setAddress(row.address);
                setIsCurrentRole(row.isCurrentRole);
                setFrom(row.from);
                setTo(row.to);
                setJobTitle(row.jobTitle);
                setDutiesSummary(row.dutiesSummary);
                setStartingSalary(row.startingSalary);
                setEndSalary(row.endSalary);
                setReasonForLeavingSummary(row.reasonForLeavingSummary);
                setReference(row.reference);
            },
        },
        removeModal: {
            title: "Remove employment",
            onRemoveSubmit: onRemoveSubmit,
        },
        body: modalBody,
        onCancel: onResetModal,
        additionalConstraints: [
            { errorMessage: "'From' cannot be after 'To'", isValid: () => !from || !to || from <= to },
        ],
    };
}
