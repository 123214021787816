import React, { useEffect, useRef, useState } from "react";
import {
    FocusZone,
    Icon,
    ISearchBoxProps,
    ISuggestionModel,
    SearchBox,
    Suggestions,
    TooltipHost,
} from "@fluentui/react";
import { useSelector } from "react-redux";
import { FollowUpIcon } from "../../FollowUpIcon";
import UAParser from "ua-parser-js";
import { UtilityHelper } from "../../../Helpers/UtilityHelper";

/**
 * Search field state for a single input field.
 *
 * For implementations where the field value state is held within the `data` redux store field, instead use the
 * "subscription wrapper" equivalent component @see SearchField.
 */
export type SuggestionSet =
    | { kind: "need-more-input" }
    | { kind: "showing"; suggestions: string[]; selectSuggestion: (index: number) => void; loadingMore: boolean }
    | { kind: "suggestion-selected" };

export interface IBasicSearchFieldProps {
    id: string;
    label: string;
    suggestions: SuggestionSet;
    value: string;
    onChange: (value: string | undefined) => void;
    showValidityMessage: boolean;
    followUpOfPreviousField?: boolean;
    pattern?: string;
    fieldErrors?: string[];
    tooltip?: JSX.Element;
    searchBoxProps?: ISearchBoxProps;
    labelProps?: React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> & {
        [dataAttribute: string]: any;
    };
}

/** Component representing a single-line search text box. */
export function BasicSearchField(props: IBasicSearchFieldProps) {
    const [isFocussed, setIsFocussed] = useState(false);
    const suggestionsContainerRef = useRef<HTMLDivElement>(null);
    const isReadOnly = useSelector((store) => store.isReadOnly);
    const uaParser = new UAParser(navigator.userAgent);
    const operatingSystem = uaParser.getOS().name;
    const isMacOS = operatingSystem === "Mac OS" || operatingSystem === "iOS";

    let suggestions: ISuggestionModel<string>[];

    if (props.suggestions.kind === "showing") {
        suggestions = props.suggestions.suggestions.map((s) => ({ item: s, selected: false }));
    } else {
        suggestions = [];
    }

    // Register a key handler to unfocus the suggestions when 'ESC' is pressed
    useEffect(() => {
        const handler = (ev: KeyboardEvent) => {
            if (ev.key === "Escape") {
                setIsFocussed(false);
            }
        };

        window.addEventListener("keyup", handler);

        // Unregister the listener on unmount
        return () => window.removeEventListener("keyup", handler);
    }, [setIsFocussed]);

    /**
     * onClick event for a suggestion. Selects a specific suggestion.
     * @param _ev Event triggering the event.
     * @param _item Item this event is triggering for
     * @param index Index of the item in suggestion collection
     */
    function onSuggestionClick(_ev?: React.MouseEvent<HTMLElement>, _item?: any, index?: number) {
        if (props.suggestions.kind === "showing" && props.suggestions.suggestions.length > 0) {
            props.suggestions.selectSuggestion(index!);
        } else {
            throw new Error("Suggestion click handler should not be called when no suggestions are retrieved");
        }
    }

    /**
     * Generates message about no suggestions found based on the kind of suggestions.
     */
    function noSuggestionsMessage(): JSX.Element {
        switch (props.suggestions.kind) {
            case "showing": {
                if (props.suggestions.loadingMore) {
                    return <></>;
                } else {
                    return <span className="suggestions-message">No results found</span>;
                }
            }

            case "suggestion-selected":
                return <></>;

            case "need-more-input":
                return <span className="suggestions-message">Type for suggestions</span>;
        }
    }

    /**
     * onBlur event handler to wipe focus.
     * @param evt onBlur event.
     */
    function onBlur(evt: React.FocusEvent<HTMLInputElement>) {
        if (isMacOS) {
            return;
        }

        const suggestionWasClicked =
            (evt.relatedTarget && suggestionsContainerRef?.current?.contains(evt.relatedTarget as HTMLElement)) ??
            false;

        // If input focus was lost because a suggestion was clicked, do not change `isFocussed` state, as it will
        // remove suggestions box, and its click event will not fire. The input stays focussed after a suggestion is
        // clicked, so `isFocussed` needn't be updated.
        if (!suggestionWasClicked) {
            setIsFocussed(false);
        }
    }

    const isSearching = props.suggestions.kind === "showing" ? props.suggestions.loadingMore : false;
    const content = (
        <>
            <label
                className={`field-label${props.searchBoxProps?.required ? " required" : ""}`}
                htmlFor={props.id}
                {...props.labelProps}
            >
                {props.followUpOfPreviousField && <FollowUpIcon />}
                {props.label}
                {props.tooltip && (
                    <TooltipHost content={props.tooltip} id={`${props.id}-tooltip`}>
                        <Icon
                            iconName="Info"
                            aria-describedby={`${props.id}-tooltip`}
                            style={{ marginLeft: "8px", cursor: "default" }}
                        />
                    </TooltipHost>
                )}
            </label>
            {isReadOnly ? (
                <span className="field-input">{props.value}</span>
            ) : (
                <SearchBox
                    id={props.id}
                    aria-invalid={props.fieldErrors && props.fieldErrors.length > 0}
                    aria-errormessage={`${props.id}-error`}
                    className="field-input"
                    placeholder="Search"
                    onFocus={() => setIsFocussed(true)}
                    onBlur={onBlur}
                    onChange={(_event, eventValue) => {
                        UtilityHelper.debounce((_e: React.ChangeEvent<HTMLInputElement>, value: string) => {
                            setIsFocussed(true);
                            props.onChange(value);
                        })(_event, eventValue);
                    }}
                    value={props.value}
                    autoComplete="off"
                    pattern={props.pattern}
                    {...props.searchBoxProps}
                    styles={{
                        root: { height: "auto" },
                        field: { fontSize: "15px" },
                        ...props.searchBoxProps?.styles,
                    }}
                />
            )}

            {isFocussed && (
                <>
                    {
                        //Fix for Mac / iOS, we add a wrapper div that when clicked sets the focus to false so we hide the callout div
                    }
                    {isMacOS && <div className="focus-div" onClick={() => setIsFocussed(false)}></div>}
                    <div className="callout" ref={suggestionsContainerRef}>
                        <Suggestions<string>
                            suggestions={suggestions}
                            onRenderSuggestion={(props, _suggestionItemProps) => (
                                <span className="suggestion">{props}</span>
                            )}
                            onRenderNoResultFound={noSuggestionsMessage}
                            onSuggestionClick={onSuggestionClick}
                            isSearching={isSearching}
                        />
                    </div>
                </>
            )}
            {props.fieldErrors && props.fieldErrors.length > 0 && (
                <div id={`${props.id}-error`} className="error" role="alert">
                    {props.fieldErrors
                        .filter((error) => error)
                        .sort((a, b) => a.localeCompare(b))
                        .map((error) => (
                            <div className="error">{error}</div>
                        ))}
                </div>
            )}
        </>
    );

    const NewFocusZone = FocusZone as any;

    return (
        <div
            className={`field search-field${props.fieldErrors && props.fieldErrors.length > 0 ? " invalid" : ""}`}
            data-field-id={props.id}
            data-field-label={props.label}
        >
            {/* Only output the <FocusZone /> when not in read-only mode */}
            {isReadOnly ? (
                content
            ) : (
                <NewFocusZone
                    className="focus-zone"
                    results={props.suggestions.kind === "showing" ? props.suggestions.suggestions.length : 0}
                    isCircularNavigation={true}
                >
                    {content}
                </NewFocusZone>
            )}
        </div>
    );
}
