import deepEqual from "deep-equal";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RequestError } from "../../Shared/Helpers/ApiHelper";
import { ApplicationFormData } from "../../Shared/Models/ApplicationFormData";
import { getFieldsUpToAndIncludingStage } from "../../Shared/Models/ApplicationStages";
import CandidateApiHelper from "../Helpers/CandidateApiHelper";

/** Status of the auto-saving */
export type AutoSaveStatus = {
    /** Whether there are pending changes to save. */
    isDirty: boolean;

    /** Whether a save is in progress. */
    saveInProgress: boolean;

    /**
     * Saves all pending changes.
     * Ensure any call to this method first checks `saveInProgress` is `false`.
     */
    flushPendingSave: () => Promise<{ hasErrors: boolean }>;
};

/** Hook for attaching auto-save behaviour for persisting the Candidate Application Form data */
export function useAutoSave(): AutoSaveStatus {
    const dispatch = useDispatch();
    const data = useSelector((store) => store.data);
    const token = useSelector((store) => store.token);
    const currentStageIndex = useSelector((store) => store.currentStageIndex);
    const modificationLock = useSelector((store) => store.modificationLock);
    const attemptedData = useRef<Partial<ApplicationFormData>>(data);
    const persistedData = useRef<Partial<ApplicationFormData>>(data);
    const timeoutId = useRef<number | undefined>(undefined);
    const [saveInProgress, setSaveInProgress] = useState(false);

    // Renew the timeout on change of the centrally stored Application Form data.
    useEffect(() => {
        window.clearTimeout(timeoutId.current);

        if (token && modificationLock?.isValid && currentStageIndex !== null) {
            timeoutId.current = window.setTimeout(() => {
                // If a save is already in progress
                if (saveInProgress) {
                    // Do not attempt to save, the current save will likely have the majority of the pending update,
                    // and if not, a future manual or auto-save will save the remainder
                    return;
                }

                // Save the latest data
                save(data);
            }, 1500);
        }
    }, [data, token, modificationLock]);

    // Propagate the `attemptedData` to the central store.
    // NOTE: Only placing this in the central store is too slow for usages in this hook.
    useEffect(() => {
        dispatch({ type: "SetAttemptedData", attemptedData: attemptedData.current });
    }, [attemptedData.current, dispatch]);

    // Clear validation errors for any data which has not yet been sent server-side.
    useEffect(() => {
        const pendingDiff = diff(data, attemptedData.current);

        if (pendingDiff) {
            Object.keys(pendingDiff).forEach((fieldName) =>
                dispatch({ type: "ClearServerSideValidationError", fieldName })
            );
        }
    }, [data, attemptedData, dispatch]);

    // Persist the current stage index, on stage index change
    useEffect(() => {
        if (!token || currentStageIndex === null) {
            return;
        }

        CandidateApiHelper.setCurrentStageIndexHint(token, currentStageIndex);
    }, [token, currentStageIndex]);

    /**
     * Saves the Application Form.
     * @param partialData Application Form Data to save.
     * @throws When `saveInProgress` is `true`, or if the token or Modification Lock has not been obtained. Ensure any
     * call to this method first checks `saveInProgress` is `false`.
     */
    async function save(partialData: Partial<ApplicationFormData>): Promise<{ hasErrors: boolean }> {
        if (!token) {
            throw new Error(`Token must be obtained before saving`);
        }

        if (!modificationLock?.identifier) {
            throw new Error(`Modification lock must be obtained before saving`);
        }

        if (saveInProgress) {
            throw new Error(`Save cannot be called while 'saveInProgress' is set true`);
        }

        if (currentStageIndex === null) {
            throw new Error(`Save cannot be called while 'currentStageIndex' is null`);
        }

        setSaveInProgress(true);

        try {
            const pendingUpdate = diff(partialData, persistedData.current);

            let errorObjects: { [serverSideFieldName: string]: string[] }[] = [];

            if (pendingUpdate) {
                try {
                    attemptedData.current = { ...attemptedData.current, ...pendingUpdate };
                    persistedData.current = await CandidateApiHelper.saveApplicationFormData(
                        token,
                        pendingUpdate,
                        modificationLock.identifier
                    );
                } catch (error) {
                    if (error instanceof RequestError && error.response.status === 400 && error.response.errors) {
                        errorObjects = errorObjects.concat(error.response.errors);
                    } else {
                        throw error;
                    }
                }
            }

            try {
                const fieldsSoFar = getFieldsUpToAndIncludingStage(currentStageIndex);
                await CandidateApiHelper.retrieveApplicationFormValidationState(token, fieldsSoFar);
            } catch (error) {
                if (error instanceof RequestError && error.response.status === 400 && error.response.errors) {
                    errorObjects = errorObjects.concat(error.response.errors);
                } else {
                    throw error;
                }
            }

            // Merge all error responses into a single error object
            const singleErrorObject = errorObjects.reduce<{ [serverSideFieldName: string]: string[] }>((all, error) => {
                for (const key in error) {
                    all[key] = error[key].concat(all[key] || []);
                }

                return all;
            }, {});

            dispatch({
                type: "SetServerSideValidationErrors",
                errors: Object.entries(singleErrorObject).map(([serverSideFieldName, errors]) => ({
                    serverSideFieldName,
                    errors,
                })),
            });

            return { hasErrors: Object.keys(singleErrorObject).length > 0 };
        } catch (error) {
            // "Conflict" as a result of the Modification Lock being invalid due to a newer session
            if (error instanceof RequestError && error.response.status === 409) {
                dispatch({
                    type: "SetModificationLock",
                    modificationLock: { ...modificationLock, isValid: false },
                });
            }

            return { hasErrors: true };
        } finally {
            setSaveInProgress(false);
        }
    }

    /**
     * Creates a partial object where the values in "a" do not equal the corresponding value in "b" (deep equality).
     * NOTE: The keys of "a" are used for iteration; keys appearing in "b" but not "a" will not be returned.
     * @param a First Application Form data object.
     * @param b Second Application Form data object.
     * @returns Diff between "a" and "b", or `null` if they are equal.
     */
    function diff(
        a: Partial<ApplicationFormData>,
        b: Partial<ApplicationFormData>
    ): Partial<ApplicationFormData> | null {
        const diff = Object.fromEntries(
            (Object.entries(a) as [keyof ApplicationFormData, any][]).filter(
                ([key, value]) => !deepEqual(value, b[key])
            )
        );

        if (Object.keys(diff).length > 0) {
            return diff;
        } else {
            return null;
        }
    }

    return {
        isDirty: diff(data, attemptedData.current) !== null,
        saveInProgress,
        flushPendingSave: () => save(data),
    };
}
