import React from "react";
import { MultiLineStringField } from "../../Components/Fields/MultiLineStringField";
import { IStage } from "../../Models/IStage";
import "../../Models/Reducers";

/** Description of the second part of the "About You" stage */
export const AboutYouPart2AdditionalInformation: IStage = {
    title: "About you",
    uniqueStageName: "About You (Part 2; Additional information)",
    fieldNames: ["hobbiesInterestsSummary", "additionalInformationSummary"],

    /** Component representing the "About You" stage */
    component: function AboutYouPart2AdditionalInformation(): JSX.Element {
        return (
            <>
                <MultiLineStringField
                    fieldName="hobbiesInterestsSummary"
                    label="What are your hobbies/interests outside of work? (Include any club memberships.)"
                    textAreaProps={{ required: true, maxLength: 2000 }}
                />
                <MultiLineStringField
                    fieldName="additionalInformationSummary"
                    label="Tell us anything else that may contribute to your application."
                    textAreaProps={{ required: false, maxLength: 2000 }}
                />
            </>
        );
    },
};
