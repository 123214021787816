import React from "react";
import { CurrentStageHeader } from "../../Components/CurrentStageHeader";
import { IStage } from "../../Models/IStage";
import { List } from "../../Components/Fields/List";
import { EducationListModal } from "./EducationListModal";
import { Formatting } from "../../Helpers/Formatting";
import { IEducationEntry } from "../../Models/ApplicationFormData";

/** Description of the "Education" stage */
export const Education: IStage = {
    title: "Education",
    uniqueStageName: "Education",
    className: "education-stage",
    fieldNames: ["education"],

    /** Additional validity constraints */
    additionalConstraints: [
        {
            isValid: ({ education }) => Boolean(education) && education!.length > 0,
            errorMessage: "At least 1 Education entry is required.",
        },
    ],

    /** Component representing the "Education" stage */
    component: function Education(): JSX.Element {
        return (
            <>
                <CurrentStageHeader />
                <p className="instruction">
                    Please include further and higher education starting with your most recent qualifications.
                </p>
                <List<IEducationEntry>
                    fieldName="education"
                    modalProps={EducationListModal()}
                    getRowTitle={row => row.institutionName}
                    headings={[
                        {
                            name: "Name of institution",
                            fieldName: "institutionName",
                            renderField: (row) => <>{row.institutionName}</>,
                        },
                        {
                            name: "FT / PT",
                            fieldName: "isFullTime",
                            renderField: (row) => <>{row.isFullTime ? "Full time" : "Part time"}</>,
                        },
                        {
                            name: "From",
                            fieldName: "from",
                            renderField: (row) => <>{row.from && Formatting.toDateString(row.from)}</>,
                        },
                        {
                            name: "To",
                            fieldName: "to",
                            renderField: (row) => <>{row.to && Formatting.toDateString(row.to)}</>,
                        },
                        {
                            name: "Qualification level",
                            fieldName: "qualificationLevel",
                            renderField: (row) => <>{row.qualificationLevel}</>,
                        },
                        {
                            name: "Qualification gained",
                            fieldName: "qualificationGained",
                            renderField: (row) => <>{row.qualificationGained}</>,
                        },
                    ]}
                />
            </>
        );
    },
};
