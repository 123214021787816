import { Icon, TooltipHost } from "@fluentui/react";
import React, { FocusEventHandler } from "react";
import { useSelector } from "react-redux";
import { FollowUpIcon } from "../../FollowUpIcon";

export interface IBasicStringFieldProps {
    id: string;
    label: string;
    value: string;
    onChange: (value: string | undefined) => void;
    showValidityMessage: boolean;
    followUpOfPreviousField?: boolean;
    fieldErrors?: string[];
    tooltip?: JSX.Element;
    onFocus?: FocusEventHandler<HTMLInputElement>;
    onBlur?: FocusEventHandler<HTMLInputElement>;
    pattern?: string;
    inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
    labelProps?: React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> & {
        [dataAttribute: string]: any;
    };
}

const DEFAULT_MAX_LENGTH = 250;

/**
 * Component representing a single-line text box.
 *
 * For implementations where the field value state is held within the `data` redux store field, instead use the
 * "subscription wrapper" equivalent component @see StringField.
 *
 * See @see BasicMultiLineStringField for multi-line fields.
 */
export function BasicStringField(props: IBasicStringFieldProps) {
    const isReadOnly = useSelector((store) => store.isReadOnly);

    return (
        <div
            className={`field string-field${props.fieldErrors && props.fieldErrors.length > 0 ? " invalid" : ""}`}
            data-field-id={props.id}
            data-field-label={props.label}
        >
            <label
                className={`field-label${props.inputProps?.required ? " required" : ""}`}
                htmlFor={props.id}
                {...props.labelProps}
            >
                {props.followUpOfPreviousField && <FollowUpIcon />}
                {props.label}
                {props.tooltip && (
                    <TooltipHost content={props.tooltip} id={`${props.id}-tooltip`}>
                        <Icon
                            iconName="Info"
                            aria-describedby={`${props.id}-tooltip`}
                            style={{ marginLeft: "8px", cursor: "default" }}
                        />
                    </TooltipHost>
                )}
            </label>
            {isReadOnly ? (
                <span className="field-input">{props.value}</span>
            ) : (
                <input
                    id={props.id}
                    aria-invalid={props.fieldErrors && props.fieldErrors.length > 0}
                    aria-errormessage={`${props.id}-error`}
                    className="field-input"
                    type="text"
                    required={true}
                    value={props.value}
                    onChange={(e) => props.onChange(e.target.value)}
                    onFocus={props.onFocus}
                    onBlur={props.onBlur}
                    pattern={props.pattern}
                    {...props.inputProps}
                    maxLength={props.inputProps?.maxLength ?? DEFAULT_MAX_LENGTH}
                />
            )}
            {props.fieldErrors && props.fieldErrors.length > 0 && (
                <div id={`${props.id}-error`} className="error" role="alert">
                    {props.fieldErrors
                        .filter((error) => error)
                        .sort((a, b) => a.localeCompare(b))
                        .map((error) => (
                            <div className="error">{error}</div>
                        ))}
                </div>
            )}
        </div>
    );
}
