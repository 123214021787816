import React from "react";
import { Icon, TooltipHost } from "@fluentui/react";
import { useSelector } from "react-redux";
import { CheckMarkIcon } from "../../CheckMarkIcon";
import { FollowUpIcon } from "../../FollowUpIcon";

/**
 * Component representing a choice between a true and false value.
 *
 * For implementations where the field value state is held within the `data` redux store field, instead use the
 * "subscription wrapper" equivalent component @see TwoOptionField.
 */
export function BasicTwoOptionField(props: {
    id: string;
    label: string;
    trueOptionLabel: string;
    falseOptionLabel: string;
    selectedOption: boolean | undefined;
    onChange: (selectedOption: boolean | undefined) => void;
    showValidityMessage: boolean;
    followUpOfPreviousField?: boolean;
    fieldErrors?: string[];
    tooltip?: JSX.Element;
    checkboxProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
    labelProps?: React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> & {
        [dataAttribute: string]: any;
    };
}) {
    const isReadOnly = useSelector((store) => store.isReadOnly);

    return (
        <div
            className={`field two-option-field${props.fieldErrors && props.fieldErrors.length > 0 ? " invalid" : ""}`}
            data-field-id={props.id}
            data-field-label={props.label}
        >
            <label className={`field-label${props.checkboxProps?.required ? " required" : ""}`} {...props.labelProps}>
                {props.followUpOfPreviousField && <FollowUpIcon />}
                {props.label}
                {props.tooltip && (
                    <TooltipHost content={props.tooltip} id={`${props.id}-tooltip`}>
                        <Icon
                            iconName="Info"
                            aria-describedby={`${props.id}-tooltip`}
                            style={{ marginLeft: "8px", cursor: "default" }}
                        />
                    </TooltipHost>
                )}
            </label>
            <div
                className="options"
                role="radiogroup"
                aria-invalid={props.fieldErrors && props.fieldErrors.length > 0}
                aria-errormessage={`${props.id}-error`}
            >
                <label
                    className="true-option"
                    htmlFor={`${props.id}-true-option`}
                    data-checked={isReadOnly ? props.selectedOption === true : undefined}
                >
                    {props.trueOptionLabel}
                    {isReadOnly ? (
                        <span className="read-only-checkbox">{props.selectedOption === true && <CheckMarkIcon />}</span>
                    ) : (
                        <input
                            id={`${props.id}-true-option`}
                            type="radio"
                            name={props.label}
                            required={true}
                            className="field-input"
                            checked={props.selectedOption === true}
                            onChange={(e) => props.onChange(true)}
                            {...props.checkboxProps}
                        />
                    )}
                </label>
                <label
                    className="false-option"
                    htmlFor={`${props.id}-false-option`}
                    data-checked={isReadOnly ? props.selectedOption === false : undefined}
                >
                    {props.falseOptionLabel}
                    {isReadOnly ? (
                        <span className="read-only-checkbox">
                            {props.selectedOption === false && <CheckMarkIcon />}
                        </span>
                    ) : (
                        <input
                            id={`${props.id}-false-option`}
                            type="radio"
                            name={props.label}
                            required={true}
                            className="field-input"
                            checked={props.selectedOption === false}
                            onChange={(e) => props.onChange(false)}
                            {...props.checkboxProps}
                            readOnly={isReadOnly}
                        />
                    )}
                </label>
            </div>
            {props.fieldErrors && props.fieldErrors.length > 0 && (
                <div id={`${props.id}-error`} className="error" role="alert">
                    {props.fieldErrors
                        .filter((error) => error)
                        .sort((a, b) => a.localeCompare(b))
                        .map((error) => (
                            <div className="error">{error}</div>
                        ))}
                </div>
            )}
        </div>
    );
}
