import React from "react";
import followUpIcon from "../Images/follow-up-icon.png";

/** Renders an icon denoting a follow-up question. */
export function FollowUpIcon(): JSX.Element {
    return (
        <img
            className="follow-up-of-previous-field-icon"
            src={followUpIcon}
            alt=">"
            height="10"
            aria-label="Follow-up question to previous field"
        />
    );
}
