/** Additional constraint to apply on submission of a form */
export type AdditionalSubmissionConstraint<T> = {
    /** Predicate for the constraint being met */
    isValid: (data: T) => boolean;

    /** Error message when the constraint is not met */
    errorMessage: string;
};

/** Helper class for validation of a form (e.g. stage form, modal form etc.) */
export class Validation {
    /**
     * Determines the validation errors on the modal form.
     * @param container Container element of the form elements to validate.
     * @param data Data model to validate.
     * @param additionalConstraints Additional constraints to validate, where applicable.
     * @returns Error messages.
     */
    public static getFormValidationErrors<T>(
        container: HTMLElement,
        data: T,
        additionalConstraints?: AdditionalSubmissionConstraint<T>[]
    ): string[] {
        // Map each invalid field to an error message
        const invalidFieldMessages = Array.from(
            container.querySelectorAll<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>(
                "input,select,textarea"
            )
        )
            // Find all invalid elements
            .filter(
                ({ validity }) =>
                    // NOTE `ValidityState.valid` is not used because `ValidityState.stepMismatch` (when an input[type="number"] has a `value`
                    // not matching the precision of `step`) is not validated.
                    validity.badInput ||
                    validity.customError ||
                    validity.patternMismatch ||
                    validity.rangeOverflow ||
                    validity.rangeUnderflow ||
                    validity.tooLong ||
                    validity.tooShort ||
                    validity.typeMismatch ||
                    validity.valueMissing
            )
            // Form an error message for the field
            .map((el) => {
                const labelElement = Validation.findAncestorElement(el, (e) =>
                    e.classList.contains("field")
                )?.querySelector(".field-label");

                const fieldName = labelElement?.firstChild?.textContent || "(Unnamed field)";

                return `'${fieldName}' invalid`;
            })
            // Remove duplicates
            .reduce<string[]>((uniqueList, errorMessage) => {
                if (!uniqueList.includes(errorMessage)) {
                    uniqueList.push(errorMessage);
                }

                return uniqueList;
            }, []);

        // Determine whether the additional constraints have not been met
        const invalidAdditionalConstraintMessages = (additionalConstraints || [])
            .filter((condition) => !condition.isValid(data))
            .map((condition) => condition.errorMessage);

        return invalidFieldMessages.concat(invalidAdditionalConstraintMessages);
    }

    /**
     * Find the innermost ancestor of an element which suits the provided predicate.
     * @param childElement Innermost element to search from.
     * @param predicate Function to test whether an ancestor element is suitable.
     * @returns The selected element if found, else `null`.
     */
    private static findAncestorElement(
        childElement: HTMLElement,
        predicate: (ancestor: HTMLElement) => boolean
    ): HTMLElement | null {
        let ancestorElement: HTMLElement | null = childElement;

        // Keep checking parents until we find the containing `<tr>`
        while (true) {
            if (ancestorElement === null) {
                return null;
            }

            if (predicate(ancestorElement)) {
                return ancestorElement;
            }

            ancestorElement = ancestorElement.parentElement;
        }
    }
}
