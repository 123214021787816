import { ISearchBoxProps } from "@fluentui/react";
import deepEqual from "deep-equal";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationFormData } from "../../Models/ApplicationFormData";
import { BasicSearchField, SuggestionSet } from "./Basic/BasicSearchField";
import { getFieldErrorMessagesAccessor, getStringFieldAccessor } from "./FieldAccessors";

type SearchFieldProps = {
    fieldName: keyof ApplicationFormData;
    label: string;
    suggestions: SuggestionSet;
    followUpOfPreviousField?: boolean;
    pattern?: string;
    patternErrorMessage?: string;
    tooltip?: JSX.Element;
    searchBoxProps?: ISearchBoxProps;
    labelProps?: React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> & {
        [dataAttribute: string]: any;
    };
};

/**
 * Component representing a single-line search text box.
 *
 * This is a "subscription wrapper" around @see BasicSearchField, designed for Application Form fields in the `data`
 * redux store field. The main aim of the "subscription wrapper" elements is a reduction in duplication and complexity
 * for the majority of field component use-cases.
 *
 * Instead, use @see BasicSearchField if the state for the intended field is not in the `data` redux store field.
 *
 * The additional behaviour this component has over the equivalent "Basic" component includes:
 * - Subscribes to a field in the central `data` redux store, both read and write.
 * - Subscribes to server-side validation errors for this field, passing them into the `fieldErrors` prop.
 * - Subscribes to the `showValidityMessage` to determine when to show certain validation errors.
 * - Diffs the current `value` versus the `attemptedValue` to determine whether the server-side errors should be displayed.
 */
export function SearchField(props: SearchFieldProps) {
    const dispatch = useDispatch();
    const fieldGetter = getStringFieldAccessor(props.fieldName);
    const fieldErrorMessagesGetter = getFieldErrorMessagesAccessor(props.fieldName);
    const value = useSelector((store) => fieldGetter(store.data));
    const attemptedValue = useSelector((store) => fieldGetter(store.attemptedData));
    const fieldErrors = useSelector((store) => fieldErrorMessagesGetter(store.serverSideValidationErrors));
    const showValidityMessage = useSelector((store) => store.currentStageSubmitted);

    const isRequired = props.searchBoxProps?.required === true;
    const errors = [
        ...(showValidityMessage && isRequired && !value ? ["Please complete field"] : []),
        ...(showValidityMessage && value && props.pattern && !new RegExp(props.pattern).test(value)
            ? [props.patternErrorMessage || "Format invalid"]
            : []),
        ...(value && deepEqual(value, attemptedValue) ? fieldErrors : []),
    ].reduce<string[]>((unique, item) => {
        if (!unique.includes(item)) {
            unique.push(item);
        }

        return unique;
    }, []);

    return (
        <BasicSearchField
            id={props.fieldName}
            label={props.label}
            onChange={(value) => dispatch({ type: "UpdateData", partialData: { [props.fieldName]: value } })}
            showValidityMessage={showValidityMessage}
            value={value || ""}
            fieldErrors={errors}
            searchBoxProps={props.searchBoxProps}
            labelProps={props.labelProps}
            pattern={props.pattern}
            suggestions={props.suggestions}
            tooltip={props.tooltip}
            followUpOfPreviousField={props.followUpOfPreviousField}
        />
    );
}
