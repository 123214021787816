import React from "react";
import { CurrentStageHeader } from "../../Components/CurrentStageHeader";
import { IStage } from "../../Models/IStage";
import { List } from "../../Components/Fields/List";
import { EmploymentListModal } from "./EmploymentListModal";
import { Formatting } from "../../Helpers/Formatting";
import { IEmploymentEntry } from "../../Models/ApplicationFormData";

/** Description of the "Employment" stage */
export const EmploymentPart1EmploymentHistory: IStage = {
    title: "Employment history",
    uniqueStageName: "Employment (Part 1; Employment History)",
    className: "employment-stage",
    fieldNames: ["employment"],

    /** Additional validity constraints */
    additionalConstraints: [
        {
            isValid: ({ employment }) => Boolean(employment) && employment!.length > 0,
            errorMessage: "At least 1 Employment entry is required.",
        },
    ],

    /** Component representing the "Employment" stage */
    component: function EmploymentPart1EmploymentHistory(): JSX.Element {
        return (
            <>
                <CurrentStageHeader />
                <p className="instruction">
                    Please list in detail, starting with your most recent position. Please include any periods when you
                    were not employed and reasons why.
                </p>
                <List<IEmploymentEntry>
                    fieldName="employment"
                    modalProps={EmploymentListModal()}
                    getRowTitle={(row) => row.employerName}
                    headings={[
                        {
                            name: "Name of employer",
                            fieldName: "employerName",
                            renderField: (row) => <>{row.employerName}</>,
                        },
                        { name: "Address", fieldName: "address", renderField: (row) => <>{row.address}</> },
                        {
                            name: "From",
                            fieldName: "from",
                            renderField: (row) => <>{row.from && Formatting.toDateString(row.from)}</>,
                        },
                        {
                            name: "To",
                            fieldName: "to",
                            renderField: (row) => <>{row.to && Formatting.toDateString(row.to)}</>,
                        },
                        { name: "Job title", fieldName: "jobTitle", renderField: (row) => <>{row.jobTitle}</> },
                        {
                            name: "Main duties",
                            fieldName: "dutiesSummary",
                            renderField: (row) => <>{row.dutiesSummary}</>,
                        },
                        {
                            name: "Starting salary",
                            fieldName: "startingSalary",
                            renderField: (row) => (
                                <>{row.startingSalary !== undefined && Formatting.toMoneyString(row.startingSalary)}</>
                            ),
                        },
                        {
                            name: "End salary",
                            fieldName: "endSalary",
                            renderField: (row) => (
                                <>{row.endSalary !== undefined && Formatting.toMoneyString(row.endSalary)}</>
                            ),
                        },
                        {
                            name: "Reason for leaving",
                            fieldName: "reasonForLeavingSummary",
                            renderField: (row) => <>{row.reasonForLeavingSummary}</>,
                        },
                        {
                            name: "Apply for reference to",
                            fieldName: "reference",
                            renderField: (row) => <>{row.reference}</>,
                        },
                    ]}
                />
            </>
        );
    },
};
