import React from "react";
import { CurrentStageHeader } from "../../Components/CurrentStageHeader";
import { MultiLineStringField } from "../../Components/Fields/MultiLineStringField";
import { IStage } from "../../Models/IStage";
import "../../Models/Reducers";

/** Description of the first part of the "About You" stage */
export const AboutYouPart1MotivationAndGoals: IStage = {
    title: "About you",
    uniqueStageName: "About You (Part 1; Motivation and Goals)",
    fieldNames: ["applicationReasonSummary", "suitableCandidateSummary", "longTermGoalsSummary"],

    /** Component representing the "About You" stage */
    component: function AboutYouPart1MotivationAndGoals(): JSX.Element {
        return (
            <>
                <CurrentStageHeader />
                <MultiLineStringField
                    fieldName="applicationReasonSummary"
                    label="Why did you apply for this position?"
                    textAreaProps={{ required: true, maxLength: 2000 }}
                />
                <MultiLineStringField
                    fieldName="suitableCandidateSummary"
                    label="Why do you think you are a suitable candidate for this role? (Include any relevant skills and experience.)"
                    textAreaProps={{ required: true, maxLength: 2000 }}
                />
                <MultiLineStringField
                    fieldName="longTermGoalsSummary"
                    label="What are your long-term goals?"
                    textAreaProps={{ required: true, maxLength: 2000 }}
                />
            </>
        );
    },
};
