import { IStage } from "./IStage";
import { PersonalDetailsPart1BasicInformation } from "../Stages/PersonalDetailsStages/PersonalDetailsPart1BasicInformation";
import { PersonalDetailsPart2Credentials } from "../Stages/PersonalDetailsStages/PersonalDetailsPart2Credentials";
import { PersonalDetailsPart3Holiday } from "../Stages/PersonalDetailsStages/PersonalDetailsPart3Holiday";
import { Education } from "../Stages/EducationStage/Education";
import { TrainingPart1TrainingHistory } from "../Stages/TrainingStages/TrainingPart1TrainingHistory";
import { TrainingPart2AdditionalSkills } from "../Stages/TrainingStages/TrainingPart2AdditionalSkills";
import { EmploymentPart1EmploymentHistory } from "../Stages/EmploymentStages/EmploymentPart1EmploymentHistory";
import { EmploymentPart2PresentOrLastEmployer } from "../Stages/EmploymentStages/EmploymentPart2PresentOrLastEmployer";
import { AboutYouPart1MotivationAndGoals } from "../Stages/AboutYouStages/AboutYouPart1MotivationAndGoals";
import { AboutYouPart2AdditionalInformation } from "../Stages/AboutYouStages/AboutYouPart2AdditionalInformation";
import { HowWeUseYourPersonalData } from "../Stages/HowWeUseYourPersonalData";
import { Declaration } from "../Stages/Declaration";
import { SubmissionConfirmation } from "../Stages/SubmissionConfirmation";
import { ApplicationFormData } from "./ApplicationFormData";
import { RequestError } from "../Helpers/ApiHelper";
import CandidateApiHelper from "../../CandidateFacing/Helpers/CandidateApiHelper";

/**
 * Stages of the Application Form
 *
 * NOTE: It is assumed the final stage is a confirmation page,
 * and that the submission stage is the penultimate one.
 */
const STAGES: IStage[] = [
    PersonalDetailsPart1BasicInformation,
    PersonalDetailsPart2Credentials,
    PersonalDetailsPart3Holiday,
    Education,
    TrainingPart1TrainingHistory,
    TrainingPart2AdditionalSkills,
    EmploymentPart1EmploymentHistory,
    EmploymentPart2PresentOrLastEmployer,
    AboutYouPart1MotivationAndGoals,
    AboutYouPart2AdditionalInformation,
    HowWeUseYourPersonalData,
    Declaration,
    SubmissionConfirmation,
];

/**
 * Gets a specific stage of the Application Form.
 * @param index Index of the stage.
 * @returns Stage description.
 */
export function getStage(index: number): IStage {
    if (index < 0 || index >= STAGES.length) {
        throw new Error(`No stage at index ${index}`);
    }

    return STAGES[index];
}

/**
 * Gets the total number of stages in the Application Form.
 * @returns total number of stages in the Application Form.
 */
export function numberOfStages(): number {
    return STAGES.length;
}

/**
 * Gets the names of all fields up to and including the stage specified.
 * @param stageIndex Index of the stage for which to retrieve fields fields up to and including the stage.
 * @returns Names of all fields up to and including the stage specified.
 */
export function getFieldsUpToAndIncludingStage(stageIndex: number): (keyof ApplicationFormData)[] {
    let fieldNames: (keyof ApplicationFormData)[] = [];

    for (let i = 0; i <= stageIndex; i++) {
        fieldNames = fieldNames.concat(STAGES[i].fieldNames);
    }

    return fieldNames;
}

/**
 * Gets the stage containing the specified field.
 * @param fieldName Name of the field for which to search.
 * @returns Description of the stage containing the specified field.
 * @throws When no unique stage was found with the specified field.
 */
export function getStageFromField(fieldName: keyof ApplicationFormData): IStage & { index: number } {
    const matches = STAGES.map((stage, index) => ({ ...stage, index })).filter((stage) =>
        stage.fieldNames.includes(fieldName)
    );

    if (matches.length === 0) {
        throw new Error(`No stage has declared ownership of the Application Form field "${fieldName}"`);
    }

    if (matches.length !== 1) {
        throw new Error(
            `Expected 1 stage to declare ownership of the Application Form field "${fieldName}"; found ${
                matches.length
            } stages: ${matches.map((stage) => `"${stage.uniqueStageName}"`).join(", ")}`
        );
    }

    return matches[0];
}

/**
 * Asserts that all the specified fields are owned by a single stage.
 * @param fieldNames Field names to assert.
 * @throws When not all the specified fields are owned by a single stage.
 */
export function assertFieldsOwnedBySingleStage(fieldNames: (keyof ApplicationFormData)[]) {
    const errors: Error[] = [];

    for (const fieldName of fieldNames) {
        try {
            getStageFromField(fieldName);
        } catch (error) {
            errors.push(error as Error);
        }
    }

    if (errors.length > 0) {
        throw new Error(
            `The following Application Form fields have incorrect stage ownership:\r\n\t${errors
                .map((error) => error.message)
                .join("\r\n\t")}`
        );
    }
}

/**
 * Finds the the earliest stage with validation errors.
 * @param candidateToken Candidate-facing token for authentication.
 * @returns Description of the earliest stage with validation errors, or the final stage if all are valid.
 * @throws When no stage declared ownership of a field related to at least one of the validation errors, or the retrieval failed.
 */
export async function retrieveEarliestInvalidStage(candidateToken: string): Promise<IStage & { index: number }> {
    let validationErrors: { [serverSideFieldName: string]: string[] };
    const finalStage = { ...getStage(numberOfStages() - 1), index: numberOfStages() - 1 };

    try {
        await CandidateApiHelper.retrieveApplicationFormValidationState(candidateToken);

        // All fields valid
        return finalStage;
    } catch (error) {
        // If an error other than a validation error occurred
        if (error instanceof RequestError && error.response.status === 400 && error.response.errors) {
            validationErrors = error.response.errors;
        } else {
            throw error;
        }
    }

    // For each stage
    for (let stageIndex = 0; stageIndex < numberOfStages(); stageIndex++) {
        const stage = getStage(stageIndex);

        // If any of the fields on the stage have validation errors
        if (
            stage.fieldNames.some((fieldName) =>
                Object.keys(validationErrors).some((x) => x.toLowerCase().startsWith(fieldName.toLowerCase()))
            )
        ) {
            return { ...stage, index: stageIndex };
        }
    }

    // Else; there were errors, but no stage declared ownership of the field
    throw new Error(
        `Validation errors were found, but no stage declared ownership of the fields related to: ${Object.keys(
            validationErrors
        )}`
    );
}
