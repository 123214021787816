import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BasicStringField } from "../../Components/Fields/Basic/BasicStringField";
import { BasicDurationField } from "../../Components/Fields/Basic/BasicDurationField";
import { BasicTwoOptionField } from "../../Components/Fields/Basic/BasicTwoOptionField";
import { IEducationEntry } from "../../Models/ApplicationFormData";
import { IListModalProps } from "../../Components/Fields/Basic/BasicList";
import { IModalProps } from "../../Components/Modal";

/** Description of the Add or Edit Education modal on the "Education" Stage */
export function EducationListModal(): IListModalProps<IEducationEntry> {
    const dispatch = useDispatch();

    const education = useSelector((store) => store.data.education);
    const [institutionName, setInstitutionName] = useState<IEducationEntry["institutionName"]>();
    const [isFullTime, setIsFullTime] = useState<IEducationEntry["isFullTime"]>();
    const [from, setFrom] = useState<IEducationEntry["from"]>();
    const [to, setTo] = useState<IEducationEntry["to"]>();
    const [qualificationLevel, setQualificationLevel] = useState<IEducationEntry["qualificationLevel"]>();
    const [qualificationGained, setQualificationGained] = useState<IEducationEntry["qualificationGained"]>();

    /** Event handler for addition of a row */
    function onAddSubmit() {
        // Append a new entry to the array
        const partialData = {
            education: [
                ...(education || []),
                {
                    institutionName: institutionName!,
                    isFullTime: isFullTime!,
                    from: from!,
                    to: to!,
                    qualificationLevel: qualificationLevel!,
                    qualificationGained: qualificationGained!,
                },
            ],
        };

        dispatch({ type: "UpdateData", partialData });
        onResetModal();
    }

    /**
     * Event handler for edit of a row.
     * @param row Edited row.
     * @param index Index of the row in the education array.
     */
    function onEditSubmit(row: IEducationEntry, index: number) {
        const partialData = {
            education: [...(education || [])],
        };

        // Replace the element at the index
        partialData.education.splice(index, 1, {
            institutionName: institutionName!,
            isFullTime: isFullTime!,
            from: from!,
            to: to!,
            qualificationLevel: qualificationLevel!,
            qualificationGained: qualificationGained!,
        });

        dispatch({ type: "UpdateData", partialData });
        onResetModal();
    }

    /**
     * Event handler for removal of a row.
     * @param row Deleted row.
     * @param index Index of the row in the education array.
     */
    function onRemoveSubmit(row: IEducationEntry, index: number) {
        // Remove the element at the index
        const partialData = { education: (education || []).filter((_, i) => i !== index) };

        dispatch({ type: "UpdateData", partialData });
    }

    /** Event handler for reset of the dialog */
    function onResetModal() {
        setInstitutionName(undefined);
        setIsFullTime(undefined);
        setFrom(undefined);
        setTo(undefined);
        setQualificationLevel(undefined);
        setQualificationGained(undefined);
    }

    const modalBody: IModalProps["body"] = (hasBeenSubmitted) => (
        <>
            <BasicStringField
                id="education-institution-name"
                onChange={setInstitutionName}
                label="Name of institution"
                value={institutionName ?? ""}
                fieldErrors={[...(hasBeenSubmitted && !institutionName ? ["Please complete field"] : [])]}
                showValidityMessage={hasBeenSubmitted}
                inputProps={{ maxLength: 120, required: true }}
            />
            <BasicTwoOptionField
                id="education-type"
                label="Type"
                onChange={setIsFullTime}
                trueOptionLabel="Full time"
                falseOptionLabel="Part time"
                selectedOption={isFullTime}
                fieldErrors={[...(hasBeenSubmitted && isFullTime === undefined ? ["Please complete field"] : [])]}
                showValidityMessage={hasBeenSubmitted}
                labelProps={{ style: { display: "none" } }}
            />
            <BasicDurationField
                id="education-duration"
                from={{
                    label: "From",
                    value: from,
                    inputProps: { required: true },
                    fieldErrors: [...(hasBeenSubmitted && !from ? ["Please complete field"] : [])],
                }}
                to={{
                    label: "To",
                    value: to,
                    inputProps: { required: true },
                    fieldErrors: [...(hasBeenSubmitted && !to ? ["Please complete field"] : [])],
                }}
                onChange={(from, to) => {
                    setFrom(from);
                    setTo(to);
                }}
                fieldErrors={[...(hasBeenSubmitted && from && to && from > to ? ["'From' cannot be after 'To'"] : [])]}
                showValidityMessage={hasBeenSubmitted}
            />
            <div className="form-row">
                <BasicStringField
                    id="education-qualification-level"
                    onChange={setQualificationLevel}
                    label="Qualification level"
                    value={qualificationLevel ?? ""}
                    showValidityMessage={hasBeenSubmitted}
                    fieldErrors={[...(hasBeenSubmitted && !qualificationLevel ? ["Please complete field"] : [])]}
                    tooltip={
                        <>
                            For example, "Undergraduate Degree", "A-Level", "GCSE".
                            <br />
                            For a full list of qualification levels, see{" "}
                            <a
                                href="https://www.gov.uk/what-different-qualification-levels-mean/list-of-qualification-levels"
                                target="_blank"
                                rel="noreferrer"
                            >
                                qualification levels guide
                            </a>
                            .
                        </>
                    }
                    inputProps={{ placeholder: "Qualification level", maxLength: 120, required: true }}
                />
                <BasicStringField
                    id="education-qualification-gained"
                    onChange={setQualificationGained}
                    label="Qualification gained"
                    value={qualificationGained ?? ""}
                    showValidityMessage={hasBeenSubmitted}
                    fieldErrors={[...(hasBeenSubmitted && !qualificationGained ? ["Please complete field"] : [])]}
                    tooltip={
                        <>
                            Title of your gained qualification eg, <br /> "BSc. Software Engineering (First class
                            honours)."
                        </>
                    }
                    inputProps={{ placeholder: "Subject and Grade", maxLength: 120, required: true }}
                />
            </div>
        </>
    );

    // Modal configuration
    return {
        addModal: {
            title: "Add education",
            submitButton: { label: "Add" },
            onAddSubmit: onAddSubmit,
        },
        editModal: {
            title: "Edit education",
            submitButton: { label: "Save" },
            onEditSubmit: onEditSubmit,
            onEditRequest: (row) => {
                setInstitutionName(row.institutionName);
                setIsFullTime(row.isFullTime);
                setFrom(row.from);
                setTo(row.to);
                setQualificationLevel(row.qualificationLevel);
                setQualificationGained(row.qualificationGained);
            },
        },
        removeModal: {
            title: "Remove education",
            onRemoveSubmit: onRemoveSubmit,
        },
        body: modalBody,
        onCancel: onResetModal,
        additionalConstraints: [
            { errorMessage: "'From' cannot be after 'To'", isValid: () => !from || !to || from <= to },
        ],
    };
}
