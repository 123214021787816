import { Component, ErrorInfo, ReactNode } from "react";

/** Properties of the component */
type ErrorBoundaryProps = {
    /** Fallback component to render on error */
    fallbackComponent: (error: Error, errorInfo: ErrorInfo) => React.ReactNode;
};

/** State of the component */
type ErrorBoundaryState = {
    /** Whether one of the children has emitted a propagated error. */
    renderState: { errored: false } | { errored: true; error: Error; errorInfo: ErrorInfo };
};

/**
 * Component acting as an error boundary, rendering the fallback component
 * specified on a fatal rendering error from its children.
 */
export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    /**
     * Initialises a new instance.
     * @param props Properties of the component.
     */
    constructor(props: ErrorBoundaryProps) {
        super(props);

        this.state = {
            renderState: { errored: false },
        };
    }

    /** @inheritdoc */
    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({ renderState: { errored: true, error, errorInfo } });
    }

    /**
     * Renders the children, or the fallback component if in an errored state.
     * @returns Children, or the fallback component if in an errored state.
     */
    public render(): ReactNode {
        const { renderState } = this.state;

        if (renderState.errored) {
            return this.props.fallbackComponent(renderState.error, renderState.errorInfo);
        }

        return this.props.children;
    }
}
