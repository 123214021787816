import { initializeIcons } from "@fluentui/react";
import React, { ErrorInfo } from "react";
import ReactDOM from "react-dom";
import { Action, createStore } from "redux";
import { DefaultRootState, Provider } from "react-redux";
import { initialState, rootReducer } from "./Shared/Models/Reducers";
import bistechLogo from "./Shared/Images/bistech-logo.png";
import { ErrorBoundary } from "./Shared/Components/ErrorBoundary";
import { ErrorPage } from "./Shared/Components/ErrorPages/ErrorPage";

/**
 * Initialises the application with the specified page element.
 * @param pageElement Page to render.
 */
export function initialisePage(pageElement: JSX.Element) {
    // Creates the Store which stores the initial state and then state when changes are made */
    const store = createStore(
        (prevState, action: Action) => {
            if (prevState) {
                return rootReducer(prevState as DefaultRootState, action);
            }

            // Default State values
            return initialState;
        },
        // redux-devtools-extension middleware: https://github.com/zalmoxisus/redux-devtools-extension
        (window as any).__REDUX_DEVTOOLS_EXTENSION__?.()
    );

    // Set the `data-pathname` and `data-querystring` on the BODY, for styling selectors
    document.body.setAttribute("data-pathname", window.location.pathname);
    document.body.setAttribute("data-querystring", window.location.search);

    ReactDOM.render(
        <Provider store={store}>
            <App>{pageElement}</App>
        </Provider>,
        document.getElementById("root")
    );
}

/** Component representing the Application */
function App(props: { children?: JSX.Element | JSX.Element[] }) {
    // Initialise any icons
    initializeIcons();

    // This is a workaround for the use of a outdated version of react-scripts PBI #36202
    // TODO: Update react-scripts and remove this cast
    const NewErrorBoundary =  ErrorBoundary as any;

    return (
        <>
            <header className="header">
                <div className="branding">
                    <img className="logo" src={bistechLogo} height="46" alt="Bistech Logo" draggable={false} />
                </div>
                <h2 className="title">Application form</h2>
            </header>
            <NewErrorBoundary 
                fallbackComponent={(error: Error, errorInfo: ErrorInfo) => (
                    <ErrorPage title="Something went wrong">
                        <p className="instruction">
                            An unexpected error occurred meaning your request could not be processed. Please try again.
                        </p>
                        <p className="instruction">
                            If the issue persists, please contact the Careers Team at Bistech on{" "}
                            <strong>01202 33 22 66</strong> or <strong>careers@bistech.co.uk</strong>
                        </p>
                        <p className="error">
                            {error.name}: {error.message}
                        </p>
                        <div className="error" style={{ whiteSpace: "pre-wrap" }}>
                            Stack trace: {errorInfo.componentStack}
                        </div>
                    </ErrorPage>
                )}
            >
                {props.children}
            </NewErrorBoundary>
        </>
    );
}
